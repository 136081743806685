<template>
  <div class="main--text mb-10" v-html="data.text"></div>
</template>

<script>
export default {
  name: "StandardTemplate",
  props: {
    data: Object,
  },
};
</script>

<style scoped></style>
