<template>
  <v-row class="section docScroll mt-6" id="tokenization">
    <SectionColumn type="left" title="Tokenization" :content="contentCol" />
    <SectionColumn type="right" :content="contentRightCol" />
  </v-row>
</template>

<script>
import SectionColumn from "../../../components/layout/SectionColumn";

export default {
  name: "Tokenization",
  components: {
    SectionColumn,
  },
  data() {
    return {
      contentCol: [
        {
          doc_section_content_id: 1,
          template: "standard",
          data: {
            text: `A recurring charge where the future dates and amounts of payments may be unknown.<br>
              Payfast will only charge the customer's card when instructed to do so via the API.<br>
              Tokenization payment agreements can be <a href="https://support.payfast.co.za/portal/en/kb/articles/can-i-set-up-a-tokenization-payment-agreement-without-charging-the-customer" target="_blank" title="Tokenization payments without charging the customer" class="link--text" rel="noreferrer">setup without charging the customer.</a>`,
          },
        },
        {
          doc_section_content_id: 2,
          template: "attributes",
          data: {
            title: "Additional tokenization form fields",
            items: [
              {
                name: "subscription_type",
                type: "integer, 1 char",
                required: true,
                required_text: "REQUIRED FOR SUBSCRIPTIONS",
                description: "2 – sets type to a tokenization payment",
              },
            ],
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 3,
          template: "request",
          data: {
            title: "Tokenization Variable",
            language: "html",
            code: '<input type="hidden" name="subscription_type" value="2">',
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
