<!--
Section Columns
Add any new templates here.
Only the first column or full column will show the title.
When there are 2 columns then the first (left) will collapse under the second (right) from the medium viewport breakpoint.
-->
<template>
  <v-col
    cols="12"
    :md="type === 'left' || type === 'right' ? 6 : 12"
    :class="type === 'right' ? 'mt-2 pa-6' : 'pa-6'"
  >
    <h2
      v-if="
        (type === 'left' || type === 'full') && typeof title !== 'undefined'
      "
    >
      {{ title }}
      <v-chip label class="px-2 py-0" color="primaryAccent" small text-color="primary"
              v-if="(badge !== '') && typeof badge !== 'undefined'">
        {{ badge }}
      </v-chip>
    </h2>
    <template v-for="block in content">
      <HeaderTemplate
        :key="`template${block.doc_section_content_id}`"
        v-if="block.template === 'header'"
        :data="block.data"
      />
      <StandardTemplate
        :key="`template${block.doc_section_content_id}`"
        v-if="block.template === 'standard'"
        :data="block.data"
      />
      <AttributesTemplate
        :key="`template${block.doc_section_content_id}`"
        v-else-if="block.template === 'attributes'"
        :data="block.data"
      />
      <RequestTemplate
        :key="`template${block.doc_section_content_id}`"
        v-else-if="block.template === 'request'"
        :data="block.data"
        :sectionId="block.doc_section_content_id"
      />
      <ResponseTemplate
        :key="`template${block.doc_section_content_id}`"
        v-else-if="block.template === 'response'"
        :data="block.data"
      />
      <TableTemplate
        :key="`template${block.doc_section_content_id}`"
        v-else-if="block.template === 'table'"
        :data="block.data"
      />
      <AlertTemplate
        :key="`template${block.doc_section_content_id}`"
        v-else-if="block.template === 'alert'"
        :data="block.data"
      />
      <IconBlockTemplate
        :key="`template${block.doc_section_content_id}`"
        v-else-if="block.template === 'icon-block'"
        :data="block.data"
      />
      <WidgetTemplate
        :key="`template${block.doc_section_content_id}`"
        v-else-if="block.template === 'widget'"
        :data="block.data"
      />
    </template>
  </v-col>
</template>

<script>
import StandardTemplate from "@/components/templates/StandardTemplate";
import AttributesTemplate from "@/components/templates/AttributesTemplate";
import RequestTemplate from "@/components/templates/RequestTemplate";
import ResponseTemplate from "@/components/templates/ResponseTemplate";
import TableTemplate from "@/components/templates/TableTemplate";
import AlertTemplate from "../templates/AlertTemplate";
import HeaderTemplate from "../templates/HeaderTemplate";
import IconBlockTemplate from "../templates/IconBlockTemplate";
import WidgetTemplate from "@/components/templates/WidgetTemplate";

export default {
  name: "SectionColumn",
  props: {
    type: String,
    title: String,
    badge: String,
    content: Array,
  },
  components: {
    StandardTemplate,
    AttributesTemplate,
    RequestTemplate,
    ResponseTemplate,
    TableTemplate,
    AlertTemplate,
    HeaderTemplate,
    IconBlockTemplate,
    WidgetTemplate,
  },
};
</script>

<style scoped></style>
