<template>
  <div>
    <RequestCard :data="data" :sectionId="sectionId" />
  </div>
</template>

<script>
import RequestCard from "@/components/templates/RequestCard";

export default {
  name: "RequestTemplate",
  props: {
    data: Object,
    sectionId: Number,
  },
  components: {
    RequestCard,
  },
};
</script>

<style scoped></style>
