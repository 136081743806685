<template>
  <v-navigation-drawer
    v-bind:value="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    v-on:input="$emit('update:drawer', $event)"
    app
    class="sidebar_nav"
    color="#022D2D"
  >
    <template v-slot:prepend>
      <v-row class="sidebar_top mt-n1 d-sm-none mb-2">
        <v-col class="text-center sidebar_top_divider" cols="6">
          <v-btn
            to="/api"
            text
            class="mx-0 px-0"
            v-if="titleText === 'DOCS'"
            aria-label="Docs"
          >
            <v-icon left class="white--text">mdi-code-braces</v-icon>
            <span class="text-capitalize white--text">APIs</span>
          </v-btn>
          <v-btn
            to="/docs"
            text
            class="mx-0 px-0"
            v-if="titleText === 'API'"
            aria-label="API"
          >
            <v-icon left class="white--text">mdi-file-document</v-icon>
            <span class="text-capitalize white--text">Dev Docs</span>
          </v-btn>
        </v-col>
        <v-col class="text-center mx-0 px-0" cols="6">
          <v-btn
            href="https://support.payfast.co.za/"
            target="_blank"
            text
            class="mx-0 px-0"
            aria-label="Support"
            rel="noreferrer"
          >
            <v-icon left class="white--text">mdi-face-agent</v-icon>
            <span class="text-capitalize white--text">Support</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-list dense v-for="(sidebarItem, i) in sidebarItems" :key="`sb${i}`">
      <v-list-item
        v-if="!sidebarItem.items"
        router
        :to="`/${currentRouteName}#${sidebarItem.attr_id}`"
      >
        <v-list-item-title>{{ sidebarItem.title }}</v-list-item-title>
      </v-list-item>

      <v-list-group
        v-else
        :key="sidebarItem.attr_id"
        v-on:input="$emit('update:drawer', true)"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-title>{{ sidebarItem.title }}</v-list-item-title>
        </template>

        <v-list-item
          v-for="item in sidebarItem.items"
          :key="item.attr_id"
          router
          :to="`/${currentRouteName}#${item.attr_id}`"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { sidebarApi } from "../../views/api/nav";
import { sidebarDocs } from "../../views/docs/nav";

export default {
  name: "SideBar",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    group: null,
  }),
  computed: {
    titleText() {
      return this.$route.name === "api" ? "API" : "DOCS";
    },
    currentRouteName() {
      return this.$route.name === null ? "docs" : this.$route.name;
    },
    sidebarItems() {
      if (this.$route.name === "api") {
        return sidebarApi;
      } else {
        return sidebarDocs;
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar_top {
  background-color: #3d4f5c;
}
.sidebar_top_divider {
  border-right: #cccccc;
  border-right-width: 1px;
  border-right-style: solid;
}

.sidebar_nav {
  .v-list--dense .v-list-item .v-list-item__title {
    font-family: "FoundersGrotesk", serif !important;
    font-size: 16px !important;
  }
  .v-list-item--active {
    background: $forestGreen100 !important;
    border-left: solid 3px $zestyLime !important;
  }
  //.theme--light.v-list-item--active:hover::before,
  //.theme--light.v-list-item--active::before {
  //  color: transparent !important;
  //}
  .v-navigation-drawer__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden !important;
    padding-top: 8px;
    padding-bottom: 8px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: $forestGreen300;
    }
  }
}


</style>
