<template>
  <div class="main--text mb-4">
    <MoreTyme :key="`widgetmoretyme`" v-if="data.type === 'moretyme'" />
  </div>
</template>

<script>
import MoreTyme from "@/components/widgets/MoreTyme";

export default {
  name: "WidgetTemplate",
  props: {
    data: Object,
  },
  components: {
    MoreTyme,
  },
};
</script>

<style scoped></style>
