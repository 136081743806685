<template>
  <v-row class="section docScroll mt-6" id="test_transaction_setup">
    <SectionColumn
      type="left"
      title="Test transaction setup"
      :content="contentLeftCol"
    />
    <SectionColumn type="right" :content="contentRightCol" />
    <SectionColumn type="left" :content="contentLeftPaymentCol" />
  </v-row>
</template>

<script>
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "TestTransactionSetup",
  components: {
    SectionColumn,
  },
  data() {
    return {
      contentLeftCol: [
        {
          doc_section_content_id: 1,
          template: "standard",
          data: {
            text: "<h3>Merchant Details:</h3>",
          },
        },
        {
          doc_section_content_id: 2,
          template: "standard",
          data: {
            text: `To get started you can use the credentials provided in your <a href="https://sandbox.payfast.co.za" class="link--text" target="_blank">Sandbox</a>, or you can use the following test credentials:`,
          },
        },
        {
          doc_section_content_id: 3,
          template: "request",
          data: {
            title: "Merchant Credentials",
            language: "html",
            code: `Merchant ID: 10000100\nMerchant Key: 46f0cd694581a\nMerchant Passphrase: jt7NOE43FZPn`,
          },
        },
        {
          doc_section_content_id: 4,
          template: "standard",
          data: {
            text: "These credentials can be found on your live or Sandbox dashboard after you have logged in.<br><br>They are unique to your account but the Sandbox merchant_id and merchant_key has no correlation to your live account.",
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 5,
          template: "table",
          data: {
            title: "Sandbox URLs:",
            items: [
              {
                name: "Post payment URL",
                description: "https://sandbox.payfast.co.za/eng/process",
              },
              {
                name: "Transaction notification URL",
                description: "https://sandbox.payfast.co.za/eng/query/validate",
              },
            ],
          },
        },
      ],
      contentLeftPaymentCol: [
        {
          doc_section_content_id: 6,
          template: "standard",
          data: {
            text: "<h2>Make payment:</h2>",
          },
        },
        {
          doc_section_content_id: 7,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Buyer credentials:</h3>" +
              "Username: &nbsp;&nbsp;&nbsp; sbtu01@payfast.io<br>" +
              "Password: &nbsp;&nbsp;&nbsp; clientpass<br>",
          },
        },
        {
          doc_section_content_id: 8,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Payfast wallet:</h3>" +
              "For a once off payment, you will see the amount given for the transaction, and a Payfast wallet (which is reset to R99,999,999.99 every night). Complete the test transaction by clicking ‘Pay Now Using Your Wallet’.",
          },
        },
        {
          doc_section_content_id: 9,
          template: "standard",
          data: {
            text:
              "<h3 class=' mb-1'>Recurring payment:</h3>" +
              "For a recurring payment, you will see a message about the recurring payment, as well as a test credit card and cvv number. In order to make the test payment, select the credit card and enter the cvv number provided and click ‘Pay’.",
          },
        },
        {
          doc_section_content_id: 10,
          template: "alert",
          data: {
            type: "warning",
            msg: `Subscriptions require a Sandbox account with passphrase setup.`,
          },
        },
        {
          doc_section_content_id: 11,
          template: "standard",
          data: {
            text: "<h2 class='mb-1'>Payment success:</h2>",
          },
        },
        {
          doc_section_content_id: 12,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Transaction notifications:</h3>" +
              "View the success transaction notification by navigating to ITN (Instant transaction notification) in the Sandbox.",
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
