<template>
  <div class="row">
    <v-col cols="12" class="col-md-6" v-for="item in data.items" :key="item.i">
      <div class="ml-12">
        <div class="d-none d-sm-inline-block float-left" style="width: 72px">
          <a
              :href="item.link"
              :target="item.target || '_parent'"
              class="no_underline"
          >
            <div v-if="item.svg" v-html="item.svg" style="width: 48px"></div>
          </a>
        </div>
        <div class="d-inline-block float-left">
          <h3>
            <a
                :href="item.link"
                :target="item.target || '_parent'"
                class="no_underline"
            >{{ item.title }}</a
            >
          </h3>
          <span v-html="item.text" class="main--text"></span>
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "IconBlockTemplate",
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
a.no_underline {
  text-decoration: none;
  color: $text-01;
}
.item-align {
  align-items: flex-start;
}
</style>
