<template>
  <v-row id="recurring_functionality" class="docScroll mt-6">
    <SectionColumn
      type="full"
      title="Additional methods of integrating Recurring Billing"
      :content="contentCol"
    />
  </v-row>
</template>

<script>
import SectionColumn from "../../../components/layout/SectionColumn";

export default {
  name: "RecurringExtended",
  components: {
    SectionColumn,
  },
  data() {
    return {
      contentCol: [
        {
          doc_section_content_id: 49,
          template: "standard",
          data: {
            text:
              "For more information on other ways of setting up Subscriptions, such as through a Pay Now button, Payment Request or ecommerce platform plugins, " +
              "<a target='_blank' class='link--text' href='https://support.payfast.co.za/portal/en/kb/articles/how-do-i-enable-recurring-payments-on-my-website' rel='noreferrer'>click here</a>",
          },
        },
        {
          doc_section_content_id: 50,
          template: "standard",
          data: {
            text:
              "<h3>Recurring Billing maintenance</h3>" +
              "<br /> " +
              "<p>Subscriptions can be edited, paused and cancelled either via the Payfast dashboard or the API.</p>" +
              "<ul>" +
              "<li>" +
              "<b>Update:</b> <a class='link--text' href='https://support.payfast.co.za/portal/en/kb/articles/managing-subscriptions-on-your-payfast-dashboard' target='_blank' rel='noreferrer'>Payfast dashboard guide</a>" +
              " | <a href='/api#update-a-subscription' class='link--text'>API guide</a>" +
              "</li>" +
              "<li>" +
              "<b>Pause:</b> <a class='link--text' href='https://support.payfast.co.za/portal/en/kb/articles/managing-subscriptions-on-your-payfast-dashboard' target='_blank' rel='noreferrer'>Payfast dashboard guide</a>" +
              " | <a href='/api#pause-a-subscription' class='link--text'>API guide</a>" +
              "</li>" +
              "<li>" +
              "<b>Cancel:</b> <a class='link--text' href='https://support.payfast.co.za/portal/en/kb/articles/managing-subscriptions-on-your-payfast-dashboard' target='_blank' rel='noreferrer'>Payfast dashboard guide</a>" +
              " | <a href='/api#cancel-a-subscription' class='link--text'>API guide</a>" +
              "</li>" +
              "</ul>",
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
