<template>
  <div>
    <v-autocomplete
      outlined
      rounded
      dense
      solo
      flat
      clearable
      hide-details
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      placeholder="Search"
      prepend-inner-icon="mdi-magnify"
      return-object
      @input="itemClicked"
      color="#439292"
      :menu-props="{ contentClass: 'search-dropdown' }"
    ></v-autocomplete>
  </div>
</template>

<script>
import { sidebarApi } from "../../views/api/nav";
import { sidebarDocs } from "../../views/docs/nav";

export default {
  name: "Searchbar",
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),
  methods: {
    itemClicked(item = null) {
      if (item !== null) {
        const val = item.value.split("|");
        const newRoute = `/${this.currentRouteName}#${val[1]}`;
        if (this.$route.fullPath !== newRoute) {
          this.$router.push({ path: newRoute });
        }
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    items() {
      let list = {};
      let returnList = [];
      if (this.$route.name === "api") {
        list = sidebarApi;
      } else {
        list = sidebarDocs;
      }
      let k = 0;
      for (let x in list) {
        if (typeof list[x].tags != "undefined" && list[x].tags.length > 0) {
          let tags = list[x].tags.map((y) => {
            k++;
            return {
              text: `${y} > ${list[x].title}`,
              value: `${k}|${list[x].attr_id}`,
            };
          });
          returnList = [...returnList, ...tags];
        }
        // Need to also loop through any sub-headings
        if (Object.getOwnPropertyDescriptor(list[x], "items")) {
          const sub = list[x].items;
          for (let i in sub) {
            if (typeof sub[i].tags != "undefined" && sub[i].tags.length > 0) {
              let tags = sub[i].tags.map((y) => {
                k++;
                return {
                  text: `${y} > ${sub[i].title}`,
                  value: `${k}|${sub[i].attr_id}`,
                };
              });
              returnList = [...returnList, ...tags];
            }
          }
        }
      }
      return returnList;
    },
  },
};
</script>

<style scoped></style>
