<template>
  <v-footer padless absolute dark color="#094848">
    <v-col class="text-center col-sm-6 footer-text caption" cols="12">
      {{ new Date().getFullYear() }} — Payfast | All right reserved
    </v-col>
    <v-col
      class="text-center footer-text caption col-sm-5 d-none d-sm-flex"
      cols="12"
    >
      PCI-DSS Level 1 Service Provider Compliant
    </v-col>
    <v-col
      class="text-center footer-text caption col-sm-1 d-none d-sm-flex"
      cols="12"
    >
      v0.1.24
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer-text {
  font-family: 'FoundersGrotesk', serif !important;
}
</style>
