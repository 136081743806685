<template>
  <v-row id="splitpayments" class="docScroll">
    <HeaderTemplate :data="contentHeader" />
    <SectionColumn
      type="left"
      title="Initial setup"
      :content="contentLeftCol"
    />
    <SectionColumn type="right" :content="contentRightCol" />
    <SectionColumn type="left" :content="split_one" />
    <SectionColumn type="right" :content="split_two" />
    <SectionColumn type="left" :content="split_three" />
    <SectionColumn type="right" :content="split_four" />
    <SectionColumn type="left" :content="split_five" />
    <SectionColumn type="right" :content="split_six" />
  </v-row>
</template>

<script>
import HeaderTemplate from "../../../components/templates/HeaderTemplate";
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "SplitPayments",
  data() {
    return {
      contentHeader: {
        title: "Split Payments",
        text: `<p>Instantly split a portion of an online payment with a third party.
               <a href="https://payfast.io/features/split-payments" target="_blank" class="link--text" rel="noreferrer">Read more</a></p>`,
        svg: `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20.5" cy="20.5" r="20.5" fill="#247373"/><path d="M18.6696 15.5915C18.2302 16.0553 17.5165 16.0553 17.0771 15.5915C16.6376 15.1276 16.6376 14.3743 17.0771 13.9104L20.4521 10.3479C20.8915 9.88403 21.6052 9.88403 22.0446 10.3479L25.4196 13.9104C25.8591 14.3743 25.8591 15.1276 25.4196 15.5915C24.9802 16.0553 24.2665 16.0553 23.8271 15.5915L22.3751 14.0588V24.2528C22.3751 25.5627 23.3841 26.6278 24.6251 26.6278H26.8751C27.4974 26.6278 28.0001 27.1584 28.0001 27.8153C28.0001 28.4721 27.4974 29.0028 26.8751 29.0028H24.6251C22.1396 29.0028 20.1251 26.8764 20.1251 24.2528C20.1251 22.9428 19.1161 21.8778 17.8751 21.8778H13.8427L15.2981 23.4141C15.7376 23.878 15.7376 24.6313 15.2981 25.0952C14.8587 25.559 14.145 25.559 13.7056 25.0952L10.3306 21.5327C9.89111 21.0688 9.89111 20.3155 10.3306 19.8516L13.7056 16.2891C14.145 15.8252 14.8587 15.8252 15.2981 16.2891C15.7376 16.753 15.7376 17.5063 15.2981 17.9702L13.8427 19.5028H17.8751C18.6942 19.5028 19.4642 19.7329 20.1251 20.1374V14.0588L18.6696 15.5952V15.5915Z" fill="white"/></svg>`,
      },
      contentLeftCol: [
        {
          doc_section_content_id: 53,
          template: "standard",
          data: {
            text: `<ol>
                      <li>You will need to enable Split Payments on your account. Setup details can be found <a href="https://support.payfast.co.za/portal/en/kb/articles/how-do-i-enable-split-payments" class='link--text' target="_blank" rel='noreferrer'>here</a>.</li>
                      <li>Only one receiving merchant can be allocated a Split Payment, per split transaction. Therefore the Merchant ID of this receiving merchant is mandatory.</li>
                      <li>All amounts used for the split must be in cents.</li>
                      <li>
                        <p>There are two methods of splitting a payment:</p>
                        <ul>
                            <li><b>Global setup:</b> By contacting <a class='link--text' href='https://payfast.io/contact' target='_blank' rel='noreferrer'>Payfast support</a> you can have a split setup on your account that will take affect on every transaction.<br><br></li>
                            <li><b>Direct request:</b> Send the split data <em>(see additional form fields below)</em> embedded in the payment request. This gives you the flexibility to determine which payments a split should take affect on.<br>
                            The data sent in this request will take precedence over anything setup on your account.<br><br>
                            Where the request involved <i>Recurring Billing</i>, the split data will continue to be used on all subsequent recurring payments.</li>
                        </ul>
                      </li>
                    </ol><br><br>`,
          },
        },
        {
          doc_section_content_id: 54,
          template: "attributes",
          data: {
            title: "Additional Split Payment form fields",
            items: [
              {
                name: "setup",
                type: "string, JSON encoded",
                required: true,
                required_text: "REQUIRED FOR SPLIT PAYMENTS",
                description:
                  "<i>NB: Not included in the signature.</i><br>The value for setup needs to contain the JSON encoded payload for split_payment as shown in the example.",
                items: [
                  {
                    name: "merchant_id",
                    type: "integer, 8 char",
                    required: true,
                    description:
                      "The third party merchant that the payment is being split with.",
                  },
                  {
                    name: "amount",
                    type: "integer",
                    required: true,
                    required_text: "REQUIRED IF NOT USING PERCENTAGE",
                    description:
                      "The amount in <b>cents</b> (ZAR), that will go to the third party merchant.",
                  },
                  {
                    name: "percentage",
                    type: "integer, 2 char",
                    required: true,
                    required_text: "REQUIRED IF NOT USING AMOUNT",
                    description:
                      "The percentage allocated to the third party merchant.",
                  },
                  {
                    name: "min",
                    type: "integer",
                    required: false,
                    description:
                      "The minimum amount that will be split, in <b>cents</b> (ZAR)",
                  },
                  {
                    name: "max",
                    type: "integer",
                    required: false,
                    description:
                      "The maximum amount that will be split, in <b>cents</b> (ZAR)",
                  },
                ],
              },
            ],
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 55,
          template: "request",
          data: {
            title: "Split Payment input",
            language: "html",
            code: `<input type="hidden" name="setup" value='{\n  "split_payment" : {\n    "merchant_id":10000105,\n    "percentage":10,\n    "min":100,\n    "max":100000\n  }\n}'>`,
          },
        },
      ],
      split_one: [
        {
          doc_section_content_id: 56,
          template: "standard",
          data: {
            text: `<h2>Split Payment calculation</h2>
                    <p><br><b>1. </b>If both percentage and amount are specified, then the percentage will be deducted first, and then the amount will be deducted from the rest.
                    <br><br>
                    <b>Split amount:</b> (40,000 – (40,000/10)) – 500) = 35,500 cents</p>`,
          },
        },
      ],
      split_two: [
        {
          doc_section_content_id: 57,
          template: "response",
          data: {
            title: "For example on an amount of R400 (40000 cents)",
            json: {
              split_payment: {
                merchant_id: 10000105,
                percentage: 10,
                amount: 500,
                min: 100,
                max: 100000,
              },
            },
          },
        },
      ],
      split_three: [
        {
          doc_section_content_id: 58,
          template: "standard",
          data: {
            text: `<b>2. </b>If the split amount is smaller than the min, then the min will be used instead of the split amount.
                    <br><br><b>Split amount:</b> (6,000 – 5500) = 500 cents (< min) = 1000 cents`,
          },
        },
      ],
      split_four: [
        {
          doc_section_content_id: 59,
          template: "response",
          data: {
            title: "For example on an amount of R60 (6000 cents)",
            json: {
              split_payment: {
                merchant_id: 10000105,
                amount: 5500,
                min: 1000,
                max: 100000,
              },
            },
          },
        },
      ],
      split_five: [
        {
          doc_section_content_id: 60,
          template: "standard",
          data: {
            text: `<b>3. </b>If the split amount is bigger than the max, then the max will be used instead of the split amount.<br><br>
                <b>Split amount:</b> 40,000 – (40,000/10) = 36,000 cents (> max) = 20 000 cents`,
          },
        },
      ],
      split_six: [
        {
          doc_section_content_id: 61,
          template: "response",
          data: {
            title: "For example on an amount of R400 (40000 cents)",
            json: {
              split_payment: {
                merchant_id: 10000105,
                percentage: 10,
                min: 100,
                max: 20000,
              },
            },
          },
        },
      ],
    };
  },
  components: {
    HeaderTemplate,
    SectionColumn,
  },
};
</script>

<style scoped></style>
