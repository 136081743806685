<template>
  <v-row id="recurring_billing" class="docScroll">
    <HeaderTemplate :data="contentHeader" />
    <SectionColumn type="full" :content="contentCol" />
    <v-col>
      <v-divider class="ma-3" />
      <Subscriptions />
      <Tokenization />
      <RecurringCardUpdate />
      <RecurringExtended />
    </v-col>
  </v-row>
</template>

<script>
import HeaderTemplate from "../../../components/templates/HeaderTemplate";
import SectionColumn from "../../../components/layout/SectionColumn";
import Subscriptions from "./Subscriptions";
import Tokenization from "./Tokenization";
import RecurringExtended from "./RecurringExtended";
import RecurringCardUpdate from "./RecurringCardUpdate";

export default {
  name: "RecurringBillingIntro",
  components: {
    SectionColumn,
    Subscriptions,
    Tokenization,
    RecurringExtended,
    RecurringCardUpdate,
    HeaderTemplate,
  },
  data() {
    return {
      contentHeader: {
        title: "Recurring Billing",
        text: `<p>Create two methods of recurring payments: Subscriptions and Tokenization.<br>
               For Recurring Billing only the credit card option can be used.</p>`,
        svg: `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20.5" cy="20.5" r="20.5" fill="#247373"/><path d="M15.6949 16.9141C15.9656 16.0625 16.4051 15.2617 17.0238 14.5781C19.2211 12.1367 22.7824 12.1367 24.9797 14.5781L25.5809 15.25H23.8125H22.6875V17.75H23.8125H28.3125H29.4375V16.5V11.5V10.25H27.1875V11.5V13.5L26.5687 12.8125C23.4926 9.39453 18.5074 9.39453 15.4312 12.8125C14.5734 13.7656 13.9547 14.8867 13.575 16.0859L15.6984 16.918L15.6949 16.9141ZM14.8125 24.5039L15.4312 25.1875C18.5074 28.6016 23.4926 28.6016 26.5652 25.1875C27.423 24.2344 28.0453 23.1133 28.425 21.918L26.3016 21.0859C26.0309 21.9375 25.5914 22.7383 24.9727 23.4219C22.7754 25.8633 19.2141 25.8633 17.0168 23.4219L17.0133 23.418L16.4156 22.75H18.1875H19.3125V20.25H18.1875H13.6875H12.5625V21.5V26.5V27.75H14.8125V26.5V24.5039Z" fill="white"/></svg>`,
      },
      contentCol: [
        {
          doc_section_content_id: 39,
          template: "standard",
          data: {
            text:
              "On successful payment completion and all subsequent recurring payments you will be sent a notification (see <a href='#step_4_confirm_payment' class='link--text'>" +
              "Confirm payment is successful</a>).<br>" +
              "A “token” parameter will be sent as part of the notification and is to be used for all further <a href='/api' class='link--text'>API calls</a> related to the subscription.<br><br>" +
              "On failed payments, Payfast will try a number of times to reprocess a payment where the customer does not have funds on their credit card. " +
              "On failure, the customer will be notified, allowing some time for the problem to be " +
              "resolved. On a complete failure (after X amount of times), the subscription will be ‘locked’ and will need some action from the merchant " +
              "to reactivate on the Payfast account or via the API pause endpoint.<br><br>" +
              "<h3 class='mb-1'>Custom integration</h3>" +
              "Recurring Billing is set up in exactly the same manner as standard payments (see the " +
              "above <a href='#quickstart' class='link--text'>Quickstart</a> guide), with the addition of the following fields.",
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
