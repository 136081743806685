<template>
  <v-alert outlined text :type="data.type">
    <span v-html="data.msg" />
  </v-alert>
</template>

<script>
export default {
  name: "AlertTemplate",
  props: {
    data: Object,
  },
};
</script>

<style scoped></style>
