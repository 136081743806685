<template>
  <div class="mt-8">
    <v-row v-for="(item, index) in data" :key="index" class="ml-0 mt-2">
      <v-col cols="12" class="divider pl-0 mb-2">
        <div class="mb-3 mainS--text">
          <v-chip label class="font-weight-medium mr-4">{{ item.name }}</v-chip>
          <span class="mr-2">{{ item.type }}</span> |
          <span
            :class="{
              'mx-2 item-required': item.required === true,
              'mx-2 item-optional': item.required !== true,
            }"
            >{{
              item.required_text
                ? item.required_text
                : item.required === true
                ? "REQUIRED"
                : "OPTIONAL"
            }}</span
          >
        </div>
        <div v-html="item.description" class="mainS--text"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AttributesChildTemplate",
  props: {
    data: Array,
  },
};
</script>

<style lang="scss" scoped>
.divider {
  border-top: 1px solid rgba(136, 151, 162, 0.5) !important;
}
.item-name {
  color: $text-01;
  font-weight: bold;
  margin-right: 10px;
  padding: 6px;
  background-color: #e9e9e9;
}
.item-required {
  @extend .foundersGroteskSemibold;
  color: $danger;
}
.item-optional {
  @extend .foundersGroteskSemibold;
  color: $forestGreen300;
}
</style>
