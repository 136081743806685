<template>
  <v-row class="section docScroll mt-6" id="recurring_card_update">
    <SectionColumn
      type="left"
      title="Update card details"
      :content="contentLeftCol"
    />
    <SectionColumn type="right" :content="contentRightCol" />
  </v-row>
</template>

<script>
import SectionColumn from "../../../components/layout/SectionColumn";

export default {
  name: "RecurringCardUpdate",
  components: {
    SectionColumn,
  },
  data() {
    return {
      contentLeftCol: [
        {
          doc_section_content_id: 1,
          template: "standard",
          data: {
            text: `Provide buyers with a link to update their card details on a Recurring Billing subscription or Tokenization charges. This link will redirect them to Payfast in order to perform the update securely.`,
          },
        },
        {
          doc_section_content_id: 2,
          template: "attributes",
          data: {
            title: "Additional tokenization form fields",
            items: [
              {
                name: "token",
                type: "string",
                required: true,
                required_text: "REQUIRED",
                description:
                  "The buyers recurring billing subscription or recurring adhoc token",
              },
              {
                name: "return",
                type: "string, no char limit",
                required: false,
                required_text: "OPTIONAL",
                description:
                  "The URL where the buyer is returned to after updating their card details (or cancelling the update). If no return url is supplied there will be no redirect.",
              },
            ],
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 3,
          template: "request",
          data: {
            title: "",
            method: "GET",
            endpoint: "/eng/recurring/update",
            language: "html",
            code: `https://www.payfast.co.za/eng/recurring/update/{token}?return={return}`,
          },
        },
        {
          doc_section_content_id: 4,
          template: "request",
          data: {
            title: "Example Usage",
            language: "html",
            code: `<a href="https://www.payfast.co.za/eng/recurring/update/00000000-0000-0000-0000-000000000000?return=http://store.example.com">Update the card for your subscription</a>`,
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
