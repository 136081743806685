<template>
  <v-container fluid>
    <DocsHome />
    <CustomIntegration />
    <OnSitePayments />
    <RecurringBillingIntro />
    <SplitPayments />
    <PCI />
    <TestingToolsIntro />
    <Widgets />
    <Ports_Ip />
    <Faqs />
    <Footer />
  </v-container>
</template>

<script>
import RecurringBillingIntro from "./sections/RecurringBillingIntro";
import SplitPayments from "./sections/SplitPayments";
import DocsHome from "./sections/DocsHome";
import TestingToolsIntro from "./sections/TestingToolsIntro";
import PCI from "./sections/PCI";
import Ports_Ip from "./sections/Ports_Ip";
import CustomIntegration from "./sections/CustomIntegration";
import Faqs from "./sections/Faqs";
import OnSitePayments from "./sections/OnSitePayments";
import Footer from "../../components/layout/Footer";
import Widgets from "@/views/docs/sections/Widgets";

export default {
  name: "Docs",
  components: {
    Footer,
    OnSitePayments,
    Faqs,
    CustomIntegration,
    DocsHome,
    RecurringBillingIntro,
    SplitPayments,
    TestingToolsIntro,
    Widgets,
    PCI,
    Ports_Ip,
  },
};
</script>
