<template>
  <SystemCard
    :title="data.title ? data.title : 'response'"
    class="foundersGroteskRegular mb-4"
  >
    <VueJsonPretty
      :path="'res'"
      :data="data.json ? data.json : ''"
      slot="cardData"
    />
  </SystemCard>
</template>

<script>
import SystemCard from "@/components/layout/SystemCard";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "ResponseTemplate",
  props: {
    data: Object,
  },
  components: {
    SystemCard,
    VueJsonPretty,
  },
};
</script>

<style scoped></style>
