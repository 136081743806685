<template>
  <v-row id="go_live" class="docScroll mt-6">
    <SectionColumn type="left" title="Going Live" :content="contentLeftCol" />
    <SectionColumn type="right" :content="contentRightCol" />
    <SectionColumn type="left" :content="contentFullCol" />
  </v-row>
</template>

<script>
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "GoingLive",
  components: {
    SectionColumn,
  },
  data() {
    return {
      contentLeftCol: [
        {
          doc_section_content_id: 93,
          template: "standard",
          data: {
            text: "If you have ensured that your inputs to Payfast are correct in test transactions with the Sandbox, and have ensured that you can handle the appropriate responses, there should be no reason why the live system should perform any differently <br> <br> To make your payments live, simply switch to your live credentials and the live URLs.",
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 94,
          template: "table",
          data: {
            title: "Live URLs:",
            items: [
              {
                name: "Post payment URL",
                description: "https://www.payfast.co.za/eng/process",
              },
              {
                name: "Transaction notification URL",
                description: "https://www.payfast.co.za/eng/query/validate",
              },
            ],
          },
        },
      ],
      contentFullCol: [
        {
          doc_section_content_id: 95,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Merchant credentials:</h3>" +
              "Make sure that you have switched to your live Merchant ID and Merchant Key.",
          },
        },
        {
          doc_section_content_id: 96,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Test transactions:</h3>" +
              "Any payments transferred while testing live will appear in your Payfast wallet. It can then simply be paid out, once you are finished with the testing.<br><br>As per our requirements, you will not be able to process payments with an amount less than ZAR 5.00<br><br>Please note that any “test” transactions processed this way will be subject to the agreed transaction fees which can unfortunately not be refunded.",
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
