<template>
  <div id="widgets-moretyme">
    <section>
      <span class="content__heading mb-3"
        >MoreTyme Add-ons for Your Website</span
      >
      <div class="small text-muted">
        Create a personalised widget for your product web pages and increase
        sales on your site.<br />The widget informs your customers how much they
        need to pay upfront and for the next two payments with MoreTyme.
      </div>
    </section>
    <section>
      <p class="mt-4 mb-3">
        Preview:
        <span class="float-right font-weight-normal small">
          <a
            href="#"
            class="link-active"
            @click="displayWidgetSettings"
            :disabled="isUpdate"
          >
            <span>Edit Widget</span>
          </a>
        </span>
      </p>
      <div
        class="border border-secondary-light py-3 px-8"
        ref="widget-preview-script-cont"
        style="min-height: 105px"
      >
        <div ref="widget-preview-script"></div>
      </div>
    </section>

    <section v-show="isSnippet">
      <div class="pb-4">
        <p class="font-weight-bold mt-4 mb-1">Code Snippet:</p>
        <div class="mb-3 small text-muted">
          Copy the code snippet into your website linked to the relevant product
          display, and simply change the amount to the price of the product
          displayed.
        </div>
        <v-alert
          dismissible
          outlined
          text
          type="success"
          class="mt-2"
          v-show="snippetCopied"
        >
          <template>
            <span
              >The Code Snippet has been successfully copied to your
              clipboard!</span
            >
          </template>
        </v-alert>

        <v-list dense>
          <v-list-item
            dense
            class="v-list-item-alt d-inline-flex text-capitalize font-weight-bold mr-1"
            @click="integrationCustom"
            v-bind:class="{ 'v-list-item-alt--active': isIntegrationCustom }"
          >
            <v-list-item-title>Custom</v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            class="v-list-item-alt d-inline-flex text-capitalize font-weight-bold mr-1"
            @click="integrationShopify"
            v-bind:class="{ 'v-list-item-alt--active': isIntegrationShopify }"
          >
            <v-list-item-title>Shopify</v-list-item-title>
          </v-list-item>
          <v-list-item
            dense
            class="v-list-item-alt d-inline-flex text-capitalize font-weight-bold mr-1"
            @click="integrationWoocommerce"
            v-bind:class="{
              'v-list-item-alt--active': isIntegrationWoocommerce,
            }"
          >
            <v-list-item-title>WooCommerce</v-list-item-title>
          </v-list-item>
        </v-list>

        <div
          id="moretyme-code-snippet-instructions"
          class="mb-3 small text-muted"
          v-if="this.widget.integration === 'shopify'"
        >
          <v-alert outlined text type="info">
            <template>
              <span>
                Before making any changes, duplicate your shopify theme and make
                changes in the duplicate so that you can easily revert any
                changes that you make.
              </span>
            </template>
          </v-alert>

          <ol>
            <li>Log into your Shopify store admin area</li>
            <li>
              Click <strong>Online Store</strong> > <strong>Themes</strong>
            </li>
            <li>
              Next to the theme you wish to edit, click
              <strong>Actions</strong>, then select <strong>Edit Code</strong>.
              <br />Or click Customize. In the top-left corner, click Theme
              Actions, then select Edit Code
            </li>
            <li>
              The code snippet for the widget needs to be included inside of the
              product page template. The product template can be found in
              various locations depending on your theme, however, the following
              are common locations:
              <ul>
                <li>templates/product.liquid</li>
                <li>sections/product-template.liquid</li>
                <li>sections/main-product.liquid</li>
                <li>sections/featured-product-form.liquid</li>
              </ul>
            </li>
            <li>
              Add the lines of code below wherever the widget should render on
              the product page. This will usually be after the price placeholder
              <strong>{%- when 'price' ...</strong> or elsewhere inside the
              product form
            </li>
          </ol>
        </div>
        <div
          class="p-2 bg-lighter d-block d-lg-flex align-items-center"
          style="justify-content: space-between"
        >
          <span
            id="moretyme-code-snippet"
            class="text-code text-break text-monospace"
            >{{ snippet }}</span
          >
          <v-btn
            rounded
            color="primary"
            class="d-block d-lg-inline-flex text-capitalize font-weight-bold"
            aria-label="Copy code"
            @click="copySnippet"
            small
          >
            <span>Copy code</span>
          </v-btn>
        </div>
      </div>
      <hr class="border border-bottom" />
      <div class="py-4">
        <span class="content__heading mb-3">MoreTyme Marketing Banner</span>
        <div class="small text-muted mb-3">
          <span
            >Download MoreTyme marketing material to promote this buy now, pay
            later payment method on your website.</span
          >
        </div>
        <div>
          <v-btn
            :href="marketingBannerPackLink"
            download
            rounded
            color="secondary"
            class="d-inline-flex py-5 px-8 text-capitalize"
            aria-label="Download"
          >
            <span
              ><svg
                class="svg-inline--fa fa-download fa-w-16"
                aria-hidden="true"
                focusable="false"
                data-prefix="fa"
                data-icon="download"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                ></path>
              </svg>
              Download</span
            >
          </v-btn>
        </div>
      </div>
    </section>
    <section v-show="isUpdate">
      <p class="font-weight-bold mt-4 mb-2">Edit MoreTyme Widget:</p>

      <div class="row mb-2">
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="pb-3">
            <div class="pb-4">Mode</div>
            <div class="radio-button mb-1">
              <label>
                <input
                  type="radio"
                  name="widget-theme"
                  v-model="widget.temp.theme"
                  @change="renderWidgetPreview"
                  value=""
                />
                <span class="ml-2">Light Mode</span>
              </label>
            </div>
            <div class="radio-button mb-1">
              <label>
                <input
                  type="radio"
                  name="widget-theme"
                  v-model="widget.temp.theme"
                  @change="renderWidgetPreview"
                  value="dark"
                />
                <span class="ml-2">Dark Mode</span>
              </label>
            </div>
          </div>
          <div class="pb-3">
            <div class="pb-4">Widget size</div>
            <div class="radio-button mb-1">
              <label>
                <input
                  type="radio"
                  name="widget-size"
                  v-model="widget.temp.size"
                  @change="renderWidgetPreview"
                  value=""
                />
                <span class="ml-2">Standard</span>
              </label>
            </div>
            <div class="radio-button mb-1">
              <label>
                <input
                  type="radio"
                  name="widget-size"
                  v-model="widget.temp.size"
                  @change="renderWidgetPreview"
                  value="small"
                />
                <span class="ml-2">Small</span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="pb-3">
            <div class="pb-4">Logos alignment</div>
            <div class="radio-button mb-2">
              <label>
                <input
                  type="radio"
                  name="widget-logo-align"
                  v-model="widget.temp['logo-align']"
                  @change="renderWidgetPreview"
                  value=""
                />
                <span class="ml-2"
                  ><img src="/img/moretyme/logo-align-standard.svg"
                /></span>
              </label>
            </div>
            <div class="radio-button mb-2">
              <label>
                <input
                  type="radio"
                  name="widget-logo-align"
                  v-model="widget.temp['logo-align']"
                  @change="renderWidgetPreview"
                  value="right"
                />
                <span class="ml-2"
                  ><img src="/img/moretyme/logo-align-right.svg"
                /></span>
              </label>
            </div>
            <div class="radio-button mb-2">
              <label>
                <input
                  type="radio"
                  name="widget-logo-align"
                  v-model="widget.temp['logo-align']"
                  @change="renderWidgetPreview"
                  value="above"
                />
                <span class="ml-2"
                  ><img src="/img/moretyme/logo-align-above.svg"
                /></span>
              </label>
            </div>
            <div class="radio-button mb-2">
              <label>
                <input
                  type="radio"
                  name="widget-logo-align"
                  v-model="widget.temp['logo-align']"
                  @change="renderWidgetPreview"
                  value="below"
                />
                <span class="ml-2"
                  ><img src="/img/moretyme/logo-align-below.svg"
                /></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="pb-3">
            <div class="pb-4">Fonts</div>
            <div class="mb-1">
              <select
                class="border"
                v-model="widget.temp.font"
                name="snippet-font"
                @change="renderWidgetPreview"
              >
                <option
                  v-for="option in widget.fonts"
                  v-bind:key="option.value"
                  v-bind:value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div v-show="widget.temp.theme !== 'dark'" class="pb-3">
            <div>Select font colour</div>
            <div>
              <input
                type="color"
                @change="renderWidgetPreview"
                v-model="widget.temp['font-color']"
                name="widget-font-color"
                ref="snippet-font-color"
              />
            </div>
          </div>
          <div v-show="widget.temp.theme === 'dark'" class="pb-3">
            <div class="pb-3">
              <div>Payfast logo type</div>
              <div class="radio-button mb-1">
                <label>
                  <input
                    type="radio"
                    name="widget-logo-type"
                    v-model="widget.temp['logo-type']"
                    @change="renderWidgetPreview"
                    value=""
                  />
                  <span class="ml-2">Green</span>
                </label>
              </div>
              <div class="radio-button mb-1">
                <label>
                  <input
                    type="radio"
                    name="widget-logo-type"
                    v-model="widget.temp['logo-type']"
                    @change="renderWidgetPreview"
                    value="white"
                  />
                  <span class="ml-2">White</span>
                </label>
              </div>
            </div>
          </div>
          <div v-show="widget.temp.size !== 'small'">
            <div>'Learn More' link colour</div>
            <div>
              <input
                type="color"
                @change="renderWidgetPreview"
                v-model="widget.temp['link-color']"
                name="widget-link-color"
                ref="snippet-link-color"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-show="isUpdate" class="float-right">
      <v-btn
        rounded
        color="secondary"
        class="d-inline-flex text-capitalize mr-2"
        aria-label="Cancel"
        @click="cancelSnippet"
      >
        Cancel
      </v-btn>
      <v-btn
        rounded
        color="secondary"
        class="d-inline-flex text-capitalize ml-1"
        aria-label="Update Code Snippet"
        @click="updateSnippet"
      >
        Update Code Snippet
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalMoreTymeWidget",
  components: {},
  data() {
    return {
      display_update: false,
      snippet_copied: false,
      marketingBannerPackLink:
        "https://content.payfast.io" +
        "/widgets/moretyme/assets/MoreTyme_Marketing_Banner_Pack.zip",
      widget: {
        font: "",
        "font-color": "",
        "link-color": "",
        "logo-align": "",
        "logo-type": "",
        size: "",
        theme: "",
        url:
          "https://content.payfast.io" + "/widgets/moretyme/widget.min.js",
        width: "",
        temp: {
          font: "Lato",
          "font-color": "#022D2D",
          "link-color": "#247373",
          "logo-align": "",
          "logo-type": "",
          size: "",
          theme: "",
          width: "",
        },
        vars: [
          "font",
          "font-color",
          "link-color",
          "logo-align",
          "logo-type",
          "size",
          "theme",
          "width",
        ],
        fonts: [
          {
            text: "Arial",
            value: "Arial",
          },
          {
            text: "Garamond",
            value: "Garamond",
          },
          {
            text: "Helvetica",
            value: "Helvetica",
          },
          {
            text: "Lato",
            value: "Lato",
          },
          {
            text: "Merriweather",
            value: "Merriweather",
          },
          {
            text: "Merriweather Sans",
            value: "Merriweather+Sans",
          },
          {
            text: "Montserrat",
            value: "Montserrat",
          },
          {
            text: "Open Sans",
            value: "Open-Sans",
          },
          {
            text: "Oswald",
            value: "Oswald",
          },
          {
            text: "PT Sans",
            value: "PT-Sans",
          },
          {
            text: "Raleway",
            value: "Raleway",
          },
          {
            text: "Roboto",
            value: "Roboto",
          },
          {
            text: "Source Sans Pro",
            value: "Source-Sans-Pro",
          },
          {
            text: "Times-New-Roman",
            value: "Times-New-Roman",
          },
          {
            text: "Verdana",
            value: "Verdana",
          },
        ],
        integration: "custom",
      },
    };
  },
  mounted() {
    let elem = document.createElement("script");
    elem.setAttribute("src", this.widget.url + "?load=false");
    document.head.appendChild(elem);

    this.renderWidgetPreview();
  },
  computed: {
    snippet() {
      // eslint-disable-next-line no-useless-escape
      let html = `<script async src="${this.snippetUrl}" type="text/javascript"><\/script>`;
      return html;
    },
    snippetUrl() {
      let qs = {
        amount: "--AMOUNT--",
      };
      let $widget = this.widget;
      let integrations = {
        shopify:
          "{{product.selected_or_first_available_variant.price | divided_by: 100}}",
        woocommerce: "<?php echo $product->get_price(); ?>",
      };
      let obj = this.isUpdate ? $widget.temp : $widget;
      let link;
      $widget.vars.forEach(function (k) {
        if (obj[k]) {
          qs[k] = obj[k].replace("#", "--HASH--");
        }
      });
      link = $widget.url + "?" + new URLSearchParams(qs).toString();
      link = link
        .replace(
          "--AMOUNT--",
          integrations[this.widget.integration] || "{product_price}"
        )
        .replaceAll("--HASH--", "#");
      return link;
    },
    isSnippet() {
      return !this.display_update;
    },
    isUpdate() {
      return this.display_update;
    },
    isIntegrationCustom() {
      return this.widget.integration === "custom";
    },
    isIntegrationShopify() {
      return this.widget.integration === "shopify";
    },
    isIntegrationWoocommerce() {
      return this.widget.integration === "woocommerce";
    },
    snippetCopied() {
      return this.snippet_copied;
    },
  },
  methods: {
    copySnippet() {
      return this.copyTextToClipboard(this.snippet);
    },
    clipboardCopySuccess() {
      this.snippet_copied = true;
      let $self = this;
      setTimeout(function () {
        $self.snippet_copied = false;
      }, 4000);
    },
    clipboardCopyError(err) {
      // eslint-disable-next-line no-console
      console.error("Copy text: Could not copy text: ", err);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard
        .writeText(text)
        .then(this.clipboardCopySuccess, this.clipboardCopyError);
    },
    fallbackCopyTextToClipboard(text) {
      let area = document.createElement("textarea");
      area.value = text;

      // Avoid scrolling to bottom
      area.style.top = "0";
      area.style.left = "0";
      area.style.position = "fixed";

      document.body.appendChild(area);
      area.focus();
      area.select();

      try {
        let successful = document.execCommand("copy");
        if (successful) {
          this.clipboardCopySuccess();
        } else {
          this.clipboardCopyError("command failed");
        }
      } catch (err) {
        this.clipboardCopyError(err);
      }

      document.body.removeChild(area);
    },
    integrationCustom() {
      this.widget.integration = "custom";
    },
    integrationShopify() {
      this.widget.integration = "shopify";
    },
    integrationWoocommerce() {
      this.widget.integration = "woocommerce";
    },
    renderWidgetPreview() {
      let elem, cont, parent, obj, $widget;
      cont = this.$refs["widget-preview-script"];
      parent = this.$refs["widget-preview-script-cont"];
      cont.innerHTML = "";
      $widget = this.widget;
      obj = this.isUpdate ? $widget.temp : $widget;
      parent.style["background-color"] = obj.theme === "dark" ? "#0A0908" : "";

      if (window.moretyme_insertElements) {
        elem = document.createElement("span");
        elem.setAttribute("src", this.snippetUrl);
        elem.setAttribute("data-amount", 1000);
        $widget.vars.forEach(function (k) {
          elem.setAttribute("data-" + k, obj[k]);
        });
        cont.appendChild(elem);
        // eslint-disable-next-line no-undef
        moretyme_insertElements(elem);
      } else {
        elem = document.createElement("script");
        elem.setAttribute("src", this.snippetUrl);
        elem.setAttribute("data-amount", 1000);
        cont.appendChild(elem);
      }
    },
    displayWidgetSettings(e) {
      e.preventDefault();
      this.display_update = true;
      this.renderWidgetPreview();
      return false;
    },
    cancelSnippet() {
      this.display_update = false;
      this.renderWidgetPreview();
    },
    updateSnippet() {
      let $widget = this.widget;
      $widget.vars.forEach(function (k) {
        $widget[k] = $widget.temp[k];
      });
      this.display_update = false;
      this.renderWidgetPreview();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-code {
  font-size: 0.875rem !important;
  font-family: PTMono-Regular, "Times New Roman";
}
a.link-active,
a.link-active:hover {
  color: $forestGreen300;
}
a.link-active[disabled],
a.link-active[disabled]:hover {
  opacity: 0.5;
  cursor: default;
  text-decoration: none;
}

.bg-lighter {
  background-color: #f0f0f0;
}

.border-secondary-light {
  border-color: #d9d9d9;
}

input[type="color"] {
  height: 24px;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.small {
  font-size: 80%;
  font-weight: 400;
}

.bg-lighter {
  background-color: #f0f0f0;
}
.p-2 {
  padding: 0.5rem !important;
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace !important;
}
.text-break {
  word-wrap: break-word !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}
.border-secondary-light {
  border-color: #d9d9d9;
}
</style>
