<template>
  <v-row id="faqs" class="docScroll">
    <HeaderTemplate :data="contentHeader" class="mb-12" />
  </v-row>
</template>

<script>
import HeaderTemplate from "@/components/templates/HeaderTemplate";

export default {
  name: "Faqs",
  components: {
    HeaderTemplate,
  },
  data() {
    return {
      contentHeader: {
        title: "FAQs",
        text: `<p>If your questions are not answered here, please see our <a href="https://support.payfast.co.za/portal/en/kb" target="_blank" class="link--text" rel="noreferrer">Knowledge Base</a>.</p>`,
        svg: `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20.5" cy="20.5" r="20.5" fill="#247373"/><g clip-path="url(#clip0_4530_42523)"><path d="M20.5 29C22.7543 29 24.9163 28.0518 26.5104 26.364C28.1045 24.6761 29 22.3869 29 20C29 17.6131 28.1045 15.3239 26.5104 13.636C24.9163 11.9482 22.7543 11 20.5 11C18.2457 11 16.0837 11.9482 14.4896 13.636C12.8955 15.3239 12 17.6131 12 20C12 22.3869 12.8955 24.6761 14.4896 26.364C16.0837 28.0518 18.2457 29 20.5 29ZM17.6379 16.8113C17.9002 16.0273 18.6041 15.5 19.391 15.5H21.3268C22.4855 15.5 23.4219 16.4949 23.4219 17.7184C23.4219 18.5129 23.0201 19.2477 22.3693 19.6449L21.2969 20.2953C21.2902 20.7523 20.935 21.125 20.5 21.125C20.0584 21.125 19.7031 20.7488 19.7031 20.2812V19.8066C19.7031 19.5043 19.8559 19.2266 20.1049 19.0754L21.5758 18.1824C21.7318 18.0875 21.8281 17.9117 21.8281 17.7219C21.8281 17.4266 21.6023 17.191 21.3268 17.191H19.391C19.2781 17.191 19.1785 17.2648 19.142 17.3773L19.1287 17.4195C18.9826 17.859 18.5244 18.0875 18.1127 17.9328C17.701 17.7781 17.4818 17.293 17.6279 16.857L17.6412 16.8148L17.6379 16.8113ZM19.4375 23.375C19.4375 23.0766 19.5494 22.7905 19.7487 22.5795C19.948 22.3685 20.2182 22.25 20.5 22.25C20.7818 22.25 21.052 22.3685 21.2513 22.5795C21.4506 22.7905 21.5625 23.0766 21.5625 23.375C21.5625 23.6734 21.4506 23.9595 21.2513 24.1705C21.052 24.3815 20.7818 24.5 20.5 24.5C20.2182 24.5 19.948 24.3815 19.7487 24.1705C19.5494 23.9595 19.4375 23.6734 19.4375 23.375Z" fill="white"/></g><defs><clipPath id="clip0_4530_42523"><rect width="17" height="18" fill="white" transform="translate(12 11)"/></clipPath></defs></svg>`,
      },
    };
  },
};
</script>

<style scoped></style>
