<template>
  <v-row class="section docScroll mt-6" id="subscriptions">
    <SectionColumn
      type="left"
      title="Subscriptions"
      :content="contentLeftCol"
    />
    <SectionColumn type="right" :content="contentRightCol" />
  </v-row>
</template>

<script>
import SectionColumn from "../../../components/layout/SectionColumn";

export default {
  name: "Subscriptions",
  components: {
    SectionColumn,
  },
  data() {
    return {
      contentLeftCol: [
        {
          doc_section_content_id: 1,
          template: "standard",
          data: {
            text: `A recurring charge on a given date.<br>Payfast will charge the credit card according to the frequency, billing date and number of payments (cycles) specified in the payment request.`,
          },
        },
        {
          doc_section_content_id: 2,
          template: "alert",
          data: {
            type: "warning",
            msg: `<b>Passphrase:</b> Please note that for Subscriptions a passphrase is REQUIRED in your <a href="#step_2_signature" class='link--text'>signature</a>.<br>To get a passphrase while testing in the sandbox, visit <a href='https://sandbox.payfast.co.za' class='link--text' target="_blank">https://sandbox.payfast.co.za</a>, under SETTINGS edit the "Salt Passphrase". You can now use the new passphrase and merchant credentials for your sandbox testing.`,
          },
        },
        {
          doc_section_content_id: 3,
          template: "attributes",
          data: {
            title: "Additional subscription form fields",
            items: [
              {
                name: "subscription_type",
                type: "integer, 1 char",
                required: true,
                required_text: "REQUIRED FOR SUBSCRIPTIONS",
                description: "1 – sets type to a subscription",
              },
              {
                name: "billing_date",
                type: "date (YYYY-MM-DD)",
                required: false,
                description:
                  "The date from which future subscription payments will be made. Eg. 2020-01-01. Defaults to current date if not set.",
              },
              {
                name: "recurring_amount",
                type: "decimal",
                required: false,
                description:
                  "Future recurring amount for the subscription in ZAR. Defaults to the ‘amount’ value if not set. There is a minimum value of 5.00.<br>It is possible to set up a subscription or tokenization payment with an initial amount of R0.00. This would be used with subscriptions if the first cycle/period is free, or, in the case of tokenization payments it is used to set up the customers account on the merchants site, allowing for future payments. If the initial amount is R0.00 the customer will be redirected to Payfast, where they will input their credit card details and go through 3D Secure, but no money will be deducted.",
              },
              {
                name: "frequency",
                type: "integer, 1 char",
                required: true,
                required_text: "REQUIRED FOR SUBSCRIPTIONS",
                description:
                  "The cycle period.<br>&nbsp;&nbsp;&nbsp;1 - Daily<br>&nbsp;&nbsp;&nbsp;2 - Weekly<br>&nbsp;&nbsp;&nbsp;3 - Monthly<br>&nbsp;&nbsp;&nbsp;4 - Quarterly<br>&nbsp;&nbsp;&nbsp;5 - Biannually<br>&nbsp;&nbsp;&nbsp;6 - Annual",
              },
              {
                name: "cycles",
                type: "integer, 1 char",
                required: true,
                required_text: "REQUIRED FOR SUBSCRIPTIONS",
                description:
                  "The number of payments/cycles that will occur for this subscription. Set to 0 for indefinite subscription.",
              },
              {
                name: "subscription_notify_email",
                type: "boolean",
                required: false,
                description: `Send the merchant an email notification 7 days before a subscription trial ends, or before a subscription amount increases.<br>
                This setting is enabled by default and can be changed via the merchant dashboard: Settings -> Recurring Billing.`,
              },
              {
                name: "subscription_notify_webhook",
                type: "boolean",
                required: false,
                description: `Send the merchant a webhook notification 7 days before a subscription trial ends, or before a subscription amount increases.<br>
                The webhook notification URL can be set via the merchant dashboard: Settings -> Recurring Billing.`,
              },
              {
                name: "subscription_notify_buyer",
                type: "boolean",
                required: false,
                description: `Send the buyer an email notification 7 days before a subscription trial ends, or before a subscription amount increases.<br>
                    This setting is enabled by default and can be changed via the merchant dashboard: Settings -> Recurring Billing.`,
              },
            ],
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 4,
          template: "request",
          data: {
            title: "Subscription Variables",
            language: "html",
            code:
              '<input type="hidden" name="subscription_type" value="1">\n' +
              '<input type="hidden" name="billing_date" value="2020-01-01">\n' +
              '<input type="hidden" name="recurring_amount" value="123.45">\n' +
              '<input type="hidden" name="frequency" value="3">\n' +
              '<input type="hidden" name="cycles" value="12">\n' +
              '<input type="hidden" name="subscription_notify_email" value="true">\n' +
              '<input type="hidden" name="subscription_notify_webhook" value="true">\n' +
              '<input type="hidden" name="subscription_notify_buyer" value="true">\n',
          },
        },
        {
          doc_section_content_id: 5,
          template: "response",
          data: {
            title: "Subscription trial webhook sample",
            json: {
              type: "subscription.free-trial",
              token: "dc0521d3-55fe-269b-fa00-b647310d760f",
              initial_amount: 0,
              amount: 10000,
              next_run: "2021-03-30",
              frequency: "3",
              item_name: "Test Item",
              item_description: "A test product",
              name_first: "John",
              name_last: "Doe",
              email_address: "john@example.com",
            },
          },
        },
        {
          doc_section_content_id: 6,
          template: "attributes",
          data: {
            title: "Webhook attributes",
            items: [
              {
                name: "type",
                type: "string",
                required: true,
                required_text: "",
                description:
                  "The type of webhook being sent, can be one of 'subscription.free-trial', 'subscription.promo' or 'subscription.update'.",
              },
              {
                name: "token",
                type: "string",
                required: true,
                description:
                  "The Unique ID on Payfast that represents the subscription.",
              },
              {
                name: "initial_amount",
                type: "integer",
                required: true,
                description:
                  "The initial amount payable during the free trial or promotion period, in <b>cents</b> (ZAR).",
              },
              {
                name: "amount",
                type: "integer",
                required: true,
                description: "The subscription amount, in <b>cents</b> (ZAR).",
              },
              {
                name: "next_run",
                type: "YYYY-MM-DD",
                required: false,
                description: "The next run date for the subscription.",
              },
              {
                name: "frequency",
                type: "integer, 1 char",
                required: false,
                required_text: "",
                description:
                  "The cycle period.<br>&nbsp;&nbsp;&nbsp;1 - Daily<br>&nbsp;&nbsp;&nbsp;2 - Weekly<br>&nbsp;&nbsp;&nbsp;3 - Monthly<br>&nbsp;&nbsp;&nbsp;4 - Quarterly<br>&nbsp;&nbsp;&nbsp;5 - Biannually<br>&nbsp;&nbsp;&nbsp;6 - Annual",
              },
              {
                name: "item_name",
                type: "string, 100 char ",
                required: false,
                description:
                  "The name of the item being charged for, or in the case of multiple items the order number.",
              },
              {
                name: "item_description",
                type: "string, 255 char ",
                required: false,
                description:
                  "The description of the item being charged for, or in the case of multiple items the order description.",
              },
              {
                name: "name_first",
                type: "string, 100 char ",
                required: false,
                description: "The customer’s first name.",
              },
              {
                name: "name_last",
                type: "string, 100 char ",
                required: false,
                description: "The customer’s last name.",
              },
              {
                name: "email_address",
                type: "string, 100 char ",
                required: false,
                description: "The customer’s email address.",
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
