<template>
  <v-row id="quickstart" class="docScroll">
    <HeaderTemplate :data="contentHeader" />
    <SectionColumn type="left" :content="contentLeftCol" />
    <SectionColumn type="right" :content="contentRightCol" />
    <v-col>
      <v-divider class="ma-3" />
      <StepOneForm />
      <StepTwoSignature />
      <StepThreePayment />
      <StepFourConfirmation />
    </v-col>
  </v-row>
</template>

<script>
import HeaderTemplate from "../../../components/templates/HeaderTemplate";
import SectionColumn from "../../../components/layout/SectionColumn";
import StepOneForm from "./StepOneForm";
import StepTwoSignature from "./StepTwoSignature";
import StepThreePayment from "./StepThreePayment";
import StepFourConfirmation from "./StepFourConfirmation";

export default {
  name: "CustomIntegration",
  data() {
    return {
      contentHeader: {
        title: "Custom Payment Integration",
        svg: `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20.5" cy="20.5" r="20.5" fill="#247373"/><path d="M22.6051 11.2969L22.2526 12.4324L17.4526 27.8699L17.1001 29.0055L19.3951 29.7031L19.7476 28.5676L24.5476 13.1301L24.9001 11.9945L22.6051 11.2969ZM25.3013 16.3438L26.1488 17.1824L29.5013 20.5L26.1488 23.8176L25.3013 24.6562L27.0001 26.3373L27.8476 25.4986L32.0476 21.3424L32.8988 20.5L32.0513 19.6613L27.8513 15.5051L27.0001 14.6627L25.3013 16.3438V16.3438ZM15.0001 14.6627L14.1526 15.5014L9.95257 19.6576L9.10132 20.5L9.94882 21.3387L14.1488 25.4949L15.0001 26.3373L16.6988 24.6562L15.8513 23.8176L12.4988 20.5L15.8513 17.1824L16.6988 16.3438L15.0001 14.6627Z" fill="white"/></svg>`,
        text: `Build a checkout form and receive payments securely from our payment platform.<br>
              This process can be used for both one-time and recurring payments.`,
      },
      contentLeftCol: [
        {
          doc_section_content_id: 2,
          template: "standard",
          data: {
            text: `<p><b>Live</b>: https://www.payfast.co.za/eng/process<br>
              <b>Sandbox</b>: https://sandbox.payfast.co.za&#8203;/eng/process</p>`,
          },
        },
        {
          doc_section_content_id: 5,
          template: "alert",
          data: {
            type: "info",
            msg: `<b>Quick start:</b> It is recommended that you create your own <a href="#sandbox" class="link--text">Sandbox</a> account to test your integration.`,
          },
        },
        {
          doc_section_content_id: 3,
          template: "standard",
          data: {
            text: `<div class="videoWrapper"><iframe width="560" height="315" src="https://www.youtube.com/embed/aEb_v5OjQX4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`,
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 4,
          template: "request",
          data: {
            title: "Simple Form Integration",
            language: "html",
            code:
              '<form action="https://www.payfast.co.za/eng/process" method="post">\n' +
              '   <input type="hidden" name="merchant_id" value="10000100">\n' +
              '   <input type="hidden" name="merchant_key" value="46f0cd694581a">\n' +
              '   <input type="hidden" name="amount" value="100.00">\n' +
              '   <input type="hidden" name="item_name" value="Test Product">\n' +
              '   <input type="submit">\n' +
              "</form>",
          },
        },
      ],
    };
  },
  components: {
    HeaderTemplate,
    SectionColumn,
    StepOneForm,
    StepTwoSignature,
    StepThreePayment,
    StepFourConfirmation,
  },
};
</script>

<style scoped></style>
