import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    defaultLanguage: "Select library",
    statusTab: "endpoint",
  },
  getters: {
    defaultLanguage: (state) => state.defaultLanguage,
    defaultStatusTab: (state) => state.statusTab,
  },
  actions: {
    setDefaultLanguage({ commit }, lang) {
      commit("SET_DEFAULT_LANGUAGE", lang);
    },
    setStatusTab({ commit }, tab) {
      commit("SET_STATUS_TAB", tab);
    },
  },
  mutations: {
    SET_DEFAULT_LANGUAGE: (state, lang) => {
      state.defaultLanguage = lang;
    },
    SET_STATUS_TAB: (state, tab) => {
      state.statusTab = tab;
    },
  },
  modules: {},
});
