<template>
  <v-row class="section docScroll mt-6" id="step_3_pay_on_payfast">
    <SectionColumn
      type="left"
      title="Step 3: Send the customer to Payfast for payment"
      :content="contentIntroLeft"
    />
    <SectionColumn type="right" :content="contentIntroRight" />
  </v-row>
</template>

<script>
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "StepThreePayment",
  data() {
    return {
      contentIntroLeft: [
        {
          doc_section_content_id: 1,
          template: "standard",
          data: {
            text: `On submission of your form, your customers will be redirected to the secure Payfast payments page.<br>Your form should be setup to post to either Payfast's live URL or the Sandbox for testing.`,
          },
        },
      ],
      contentIntroRight: [
        {
          doc_section_content_id: 2,
          template: "request",
          data: {
            title: "Full form implementation",
            language: "php",
            code: {
              PHP: `<?php
// Construct variables
$cartTotal = 10.00; // This amount needs to be sourced from your application
$passphrase = 'jt7NOE43FZPn';
$data = array(
    // Merchant details
    'merchant_id' => '10000100',
    'merchant_key' => '46f0cd694581a',
    'return_url' => 'http://www.yourdomain.co.za/return.php',
    'cancel_url' => 'http://www.yourdomain.co.za/cancel.php',
    'notify_url' => 'http://www.yourdomain.co.za/notify.php',
    // Buyer details
    'name_first' => 'First Name',
    'name_last'  => 'Last Name',
    'email_address'=> 'test@test.com',
    // Transaction details
    'm_payment_id' => '1234', //Unique payment ID to pass through to notify_url
    'amount' => number_format( sprintf( '%.2f', $cartTotal ), 2, '.', '' ),
    'item_name' => 'Order#123'
);

$signature = generateSignature($data, $passphrase);
$data['signature'] = $signature;

// If in testing mode make use of either sandbox.payfast.co.za or www.payfast.co.za
$testingMode = true;
$pfHost = $testingMode ? 'sandbox.payfast.co.za' : 'www.payfast.co.za';
$htmlForm = '<form action="https://'.$pfHost.'/eng/process" method="post">';
foreach($data as $name=> $value)
{
    $htmlForm .= '<input name="'.$name.'" type="hidden" value=\\''.$value.'\\' />';
}
$htmlForm .= '<input type="submit" value="Pay Now" /></form>';
echo $htmlForm;`,
              "Node.js":
                "const myData = [];\n" +
                "// Merchant details\n" +
                'myData["merchant_id"] = "10000100";\n' +
                'myData["merchant_key"] = "46f0cd694581a";\n' +
                'myData["return_url"] = "http://www.yourdomain.co.za/return_url";\n' +
                'myData["cancel_url"] = "http://www.yourdomain.co.za/cancel_url";\n' +
                'myData["notify_url"] = "http://www.yourdomain.co.za/notify_url";\n' +
                "// Buyer details\n" +
                'myData["name_first"] = "First Name";\n' +
                'myData["name_last"] = "Last Name";\n' +
                'myData["email_address"] = "test@test.com";\n' +
                "// Transaction details\n" +
                'myData["m_payment_id"] = "1234";\n' +
                'myData["amount"] = "10.00";\n' +
                'myData["item_name"] = "Order#123";\n\n' +
                "// Generate signature\n" +
                'const myPassphrase = "jt7NOE43FZPn";\n' +
                'myData["signature"] = generateSignature(myData, myPassphrase);\n\n' +
                'let htmlForm = `<form action="https://${pfHost}/eng/process" method="post">`;\n' +
                "for (let key in myData) {\n" +
                "  if(myData.hasOwnProperty(key)){\n" +
                "    value = myData[key];\n" +
                '    if (value !== "") {\n' +
                '      htmlForm +=`<input name="${key}" type="hidden" value="${value.trim()}" />`;\n' +
                "    }\n" +
                "  }\n" +
                "}\n\n" +
                'htmlForm += \'<input type="submit" value="Pay Now" /></form>\';',
              Python: `data = {
    # Merchant details
    'merchant_id': '10000100',
    'merchant_key': '46f0cd694581a',
    'return_url': 'http://www.yourdomain.co.za/return.php',
    'cancel_url': 'http://www.yourdomain.co.za/cancel.php',
    'notify_url': 'http://www.yourdomain.co.za/notify.php',
    # Buyer details
    'name_first': 'First Name',
    'name_last': 'Last Name',
    'email_address': 'test@test.com',
    # Transaction details
    'm_payment_id': '1234', #Unique payment ID to pass through to notify_url
    'amount': "200",
    'item_name': 'Order#123'
}

# Generate signature (see step 2)
passphase = 'jt7NOE43FZPn';
signature = generateSignature(data, passphase);
data['signature'] = signature;

# If in testing mode make use of either sandbox.payfast.co.za or www.payfast.co.za
SANDBOX_MODE = True;
pfHost = 'sandbox.payfast.co.za' if SANDBOX_MODE else 'www.payfast.co.za'


htmlForm = f'<form action="https://{pfHost}/eng/process" method="post">'
for key in data:
    htmlForm += f'<input name="{key}" type="hidden" value="{data[key]}" />'

htmlForm += '<input type="submit" value="Pay Now" /></form>'`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
    };
  },
  components: {
    SectionColumn,
  },
};
</script>

<style scoped></style>
