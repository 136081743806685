<template>
  <v-col cols="12" class="header-block mb-3">
    <div
      class="d-none d-sm-inline-block float-left"
      style="width: 60px; height: 100%"
      v-if="data.svg"
    >
      <div v-html="data.svg" style="width: 42px"></div>
    </div>
    <h1>
      {{ data.title }}
    </h1>
    <p class="main--text" v-html="data.text"></p>
  </v-col>
</template>

<script>
export default {
  name: "AlertTemplate",
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
.header-block {
  background-color: $beige;
  padding: 24px 24px 24px 24px;
  h1 {
    font-size: 28px;
  }
}
</style>
