<template>
  <div class="mb-4">
    <v-card class="mx-auto" color="blue-grey darken-3">
      <v-system-bar color="blue-grey darken-4">
        <v-list-item-title class="text-wrap py-2">
          <h5 class="text--lighten-3 blue-grey--text foundersGroteskRegular">
            <span :class="methodClass">{{
              data.title ? data.title : data.method
            }}</span>
            {{ data.endpoint ? data.endpoint : "" }}
          </h5>
        </v-list-item-title>
        <v-spacer></v-spacer>
        <v-select
          v-if="data.codeExamples"
          :items="data.codeExamples"
          :value="defaultLanguage"
          :label="defaultLabel"
          flat
          dark
          solo
          single-line
          class="selector"
          :menu-props="{
            contentClass: 'request-card-dropdown',
          }"
          @change="changeDefaultLang($event)"
        >
          <template slot="selection" slot-scope="{ item }">
            <span>{{ item }}</span>
          </template>
          <template slot="item" slot-scope="{ item }">
            <span class="outer-item-container"
              ><span class="only-on-active"
                ><span class="mdi mdi-check"></span></span
              >{{ item }}</span
            >
          </template>

          <template v-slot:append>
            <span class="mdi mdi-unfold-more-horizontal"></span>
          </template>
        </v-select>

        <v-tooltip top v-model="show" color="primary">
          <template v-slot:activator="attrs">
            <v-icon
              class="text--lighten-3 blue-grey--text"
              @click.stop.prevent="copyRequest(getValues)"
              v-bind="attrs"
              @click="show = true"
              @mouseleave="hoverOffCopy"
              >mdi-file-multiple-outline</v-icon
            >
          </template>
          <span>Copied!</span>
        </v-tooltip>
      </v-system-bar>

      <v-card-text>
        <prism :language="data.language ? data.language : 'html'"
          >{{ getValues }}
        </prism>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import "prismjs";
import "@/assets/css/prism.css";
import "prismjs/components/prism-scss.min";
import Prism from "vue-prism-component";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-php";
import "prismjs/components/prism-json";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-python.min";
// import "prismjs/plugins/autolinker/prism-autolinker.min";
// import "prismjs/plugins/autolinker/prism-autolinker.css";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "RequestCard",
  data: () => ({
    codeText: "",
    show: false,
  }),
  props: {
    data: Object,
    sectionId: Number,
  },
  methods: {
    ...mapActions(["setDefaultLanguage"]),
    copyRequest(values) {
      this.$clipboard(values);
    },
    hoverOffCopy() {
      setTimeout(() => {
        this.show = false;
      }, 600);
    },
    changeDefaultLang(event) {
      this.setDefaultLanguage(event);
    },
  },
  computed: {
    ...mapGetters(["defaultLanguage"]),
    methodClass() {
      switch (this.data.method) {
        case "GET":
          return "text--lighten-2 blue--text";
        case "PUT":
        case "PATCH":
          return "text--lighten-3 orange--text";
        case "POST":
          return "text--lighten-2 green--text";
        case "DELETE":
          return "text--darken-1 red--text";
        default:
          return "text--lighten-3 blue-grey--text";
      }
    },
    defaultLabel() {
      // Check if current select language has a code sample
      if (this.data.codeExamples.includes(this.defaultLanguage)) {
        return this.data.code[this.defaultLanguage];
      } else if (this.defaultLanguage !== "Select library") {
        //Otherwise show first selected sample found
        return Object.keys(this.data.code)[0];
      } else {
        //Otherwise show first selected sample found
        return "Select library";
      }
    },
    getValues() {
      // Check if there is a single or multiple code blocks
      if (Object.getOwnPropertyDescriptor(this.data, "codeExamples")) {
        // Check if current select language has a code sample
        if (this.data.codeExamples.includes(this.defaultLanguage)) {
          return this.data.code[this.defaultLanguage];
        } else {
          //Otherwise show first selected sample found
          return this.data.code[this.data.codeExamples[0]];
        }
      } else {
        return this.data.code;
      }
    },
  },
  components: {
    Prism,
  },
};
</script>

<style scoped>
.selector {
  height: 45px;
  max-width: 160px;
}
.only-on-active {
  display: none;
}
.outer-item-container {
  position: relative;
}

>>> .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 20px;
}
>>> .theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
  background-color: transparent;
}
>>> .theme--light.v-system-bar .v-icon {
  color: #cccccc;
}
>>> .v-label,
.v-input {
  font-size: 14px;
}
</style>
