export const sidebarApi = [
  {
    title: "Introduction",
    attr_id: "introduction",
    tags: ["introduction", "getting started"],
    items: [
      {
        title: "Ping API response",
        attr_id: "ping-response",
        tags: ["ping", "up", "status"],
      },
    ],
  },
  {
    title: "Authentication",
    attr_id: "authentication",
    tags: ["security", "authentication"],
  },
  {
    title: "Errors",
    attr_id: "errors",
    tags: ["Error Handling"],
  },
  {
    title: "Recurring Billing",
    attr_id: "recurring-billing-group",
    items: [
      {
        title: "Overview",
        attr_id: "recurring-billing",
        tags: ["subscriptions", "recurring"],
      },
      {
        title: "The subscription object",
        attr_id: "subscription-object-fetch",
        tags: ["Subscriptions"],
      },
      {
        title: "Pause a subscription",
        attr_id: "pause-a-subscription",
        tags: ["Subscriptions", "pause"],
      },
      {
        title: "Unpause a subscription",
        attr_id: "unpause-a-subscription",
        tags: ["Subscriptions", "unpause"],
      },
      {
        title: "Cancel a subscription",
        attr_id: "cancel-a-subscription",
        tags: ["subscriptions", "cancel"],
      },
      {
        title: "Update a subscription",
        attr_id: "update-a-subscription",
        tags: ["subscriptions", "update"],
      },
      {
        title: "Charge a tokenization payment",
        attr_id: "create-an-ad-hoc-subscription",
        tags: ["subscriptions", "charge"],
      },
    ],
  },
  {
    title: "Transaction history",
    attr_id: "transaction-history-group",
    items: [
      {
        title: "Overview",
        attr_id: "transaction-history",
        tags: ["transactions", "history"],
      },
      {
        title: "History for specific dates",
        attr_id: "transaction-history-for-specific-dates",
        tags: ["transactions", "history"],
      },
      {
        title: "History for a set period",
        attr_id: "transaction-history-for-a-set-period",
        tags: ["transactions", "history"],
      },
    ],
  },
  {
    title: "Credit card transaction query",
    attr_id: "credit-card-transactions-group",
    items: [
      {
        title: "Overview",
        attr_id: "credit-card-transactions",
        tags: ["credit card", "transactions"],
      },
      {
        title: "Query a credit card transaction",
        attr_id: "query-a-credit-card-transaction",
        tags: ["credit card", "transactions"],
      },
    ],
  },
  {
    title: "Refunds",
    attr_id: "refunds-group",
    items: [
      {
        title: "Overview",
        attr_id: "refunds",
        tags: ["refund"],
      },
      {
        title: "Query a refund",
        attr_id: "refund-query",
        tags: ["refund", "query"],
      },
      {
        title: "Create a refund",
        attr_id: "refund-create",
        tags: ["refund", "create"],
      },
      {
        title: "Retrieve a refund",
        attr_id: "refund-retrieve",
        tags: ["refund", "retrieve"],
      },
    ],
  },
  {
    title: "System Status",
    attr_id: "system-status-group",
    items: [
      {
        title: "Overview",
        attr_id: "system-status",
        tags: ["status"],
      },
      {
        title: "1. Summary",
        attr_id: "system-status-summary",
        tags: ["status"],
      },
      {
        title: "2. Status",
        attr_id: "system-status-status",
        tags: ["status"],
      },
      {
        title: "3. Components",
        attr_id: "system-status-components",
        tags: ["status", "incident"],
      },
      {
        title: "4. Incidents",
        attr_id: "system-status-incidents",
        tags: ["status", "incident"],
      },
      {
        title: "4.1 Unresolved Incidents",
        attr_id: "system-status-unresolved-incidents",
        tags: ["status", "incident"],
      },
      {
        title: "4.2 All Incidents",
        attr_id: "system-status-all-incidents",
        tags: ["status", "incident"],
      },
      {
        title: "5. Scheduled Maintenance",
        attr_id: "system-status-scheduled-maintenances",
        tags: ["status", "maintenance"],
      },
      {
        title: "5.1 Upcoming Scheduled Maintenance",
        attr_id: "system-status-upcoming-scheduled-maintenances",
        tags: ["status", "maintenance"],
      },
      {
        title: "5.2 Active Scheduled Maintenance",
        attr_id: "system-status-active-scheduled-maintenances",
        tags: ["status", "maintenance"],
      },
      {
        title: "5.3 All Scheduled Maintenance",
        attr_id: "system-status-all-scheduled-maintenances",
        tags: ["status", "maintenance"],
      },
    ],
  },
];
