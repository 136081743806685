<template>
  <v-snackbar v-model="updateExists" bottom right light :timeout="-1">
    <div class="d-flex justify-space-between align-center">
    An update is available
    <v-btn
      rounded
      color="primary"
      class="d-sm-none d-md-inline-flex py-5 text-capitalize font-weight-bold"
      @click="refreshApp"
    >
      Update
    </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import update from "../../mixins/update.js";

export default {
  name: "SWUpdate",
  mixins: [update],
};
</script>

<style scoped></style>
