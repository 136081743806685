<template>
  <v-card class="mx-auto" color="blue-grey lighten-5">
    <v-system-bar color="blue-grey lighten-4" dark>
      <v-list-item-title class="text-wrap py-2">
        <h5
          class="blue-grey--text text--darken-3 font-weight-bold text-uppercase"
        >
          {{ title }}
        </h5>
      </v-list-item-title>
    </v-system-bar>

    <v-card-text>
      <slot name="cardData"></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SystemCard",
  props: {
    title: String,
  },
};
</script>

<style lang="scss">
.v-system-bar {
  height: auto !important;
}
</style>
