<template>
  <v-row id="testing-tools" class="docScroll">
    <HeaderTemplate :data="contentHeader" />
    <v-col>
      <Sdk />
      <Sandbox />
      <TestTransactionSetup />
      <GoingLive />
    </v-col>
  </v-row>
</template>

<script>
import HeaderTemplate from "@/components/templates/HeaderTemplate";
import Sandbox from "./Sandbox";
import TestTransactionSetup from "./TestTransactionSetup";
import GoingLive from "./GoingLive";
import Sdk from "./Sdk";

export default {
  name: "TestingToolsIntro",
  data() {
    return {
      contentHeader: {
        title: "Testing and tools",
        text: `<p>Use our Sandbox to test your integration before going live.</p>`,
        svg: `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20.5" cy="20.5" r="20.5" fill="#247373"/><path d="M14.7643 10.186C14.4303 9.91138 13.9557 9.94478 13.6533 10.2602L12.2471 11.7446C11.9483 12.06 11.9166 12.561 12.1733 12.9172L14.9858 16.7766C15.144 16.9956 15.3936 17.1254 15.6537 17.1254H17.5557L21.3877 21.1704C20.8709 22.2465 21.0362 23.5973 21.8904 24.4954L25.8279 28.6516C26.2674 29.1155 26.9811 29.1155 27.4205 28.6516L29.6705 26.2766C30.11 25.8128 30.11 25.0594 29.6705 24.5956L25.733 20.4393C24.8822 19.5413 23.6026 19.3631 22.583 19.9086L18.751 15.8637V13.8598C18.751 13.5815 18.6279 13.3217 18.4205 13.1547L14.7643 10.186ZM12.7006 24.6995C12.2541 25.1708 12.001 25.8128 12.001 26.4807C12.001 27.8723 13.0697 29.0004 14.3881 29.0004C15.0209 29.0004 15.6291 28.7333 16.0756 28.262L20.217 23.8905C19.9428 23.1149 19.9006 22.2725 20.0904 21.4747L17.9213 19.185L12.7006 24.6995ZM30.001 15.3442C30.001 14.9545 29.9623 14.576 29.8885 14.2124C29.8041 13.7967 29.3225 13.6891 29.0377 13.9897L26.7912 16.361C26.6858 16.4723 26.5416 16.5354 26.394 16.5354H24.376C24.0666 16.5354 23.8135 16.2682 23.8135 15.9417V13.8079C23.8135 13.652 23.8733 13.4999 23.9787 13.3885L26.2252 11.0172C26.51 10.7167 26.408 10.2083 26.0143 10.1192C25.6662 10.0413 25.3076 10.0004 24.9385 10.0004C22.1436 10.0004 19.876 12.394 19.876 15.3442V15.3739L22.8748 18.5393C24.1404 18.2016 25.5397 18.5579 26.5311 19.6044L27.083 20.187C28.8057 19.3335 30.001 17.4854 30.001 15.3442ZM13.9697 26.0317C13.9697 25.7955 14.0586 25.569 14.2169 25.4019C14.3751 25.2349 14.5897 25.1411 14.8135 25.1411C15.0373 25.1411 15.2519 25.2349 15.4101 25.4019C15.5684 25.569 15.6572 25.7955 15.6572 26.0317C15.6572 26.2679 15.5684 26.4944 15.4101 26.6615C15.2519 26.8285 15.0373 26.9223 14.8135 26.9223C14.5897 26.9223 14.3751 26.8285 14.2169 26.6615C14.0586 26.4944 13.9697 26.2679 13.9697 26.0317Z" fill="white"/></svg>`,
      },
    };
  },
  components: {
    Sdk,
    HeaderTemplate,
    Sandbox,
    TestTransactionSetup,
    GoingLive,
  },
};
</script>

<style scoped></style>
