<template>
  <v-row class="section docScroll" id="sandbox">
    <SectionColumn type="left" title="The Sandbox" :content="contentLeftCol" />
    <SectionColumn type="right" :content="contentRightCol" />
    <SectionColumn type="full" :content="contentSandboxCol" />
  </v-row>
</template>

<script>
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "Sandbox",
  data() {
    return {
      contentLeftCol: [
        {
          doc_section_content_id: 68,
          template: "standard",
          data: {
            text: `The <a href="https://sandbox.payfast.co.za" class="link--text" target="_blank">Payfast Sandbox</a> is an exact code duplicate of the production site, available for running test transactions with.`,
          },
        },
        {
          doc_section_content_id: 69,
          template: "standard",
          data: {
            text: "Any transactions made or actions performed on this system are isolated from the main production environment, while providing a realistic experience of your integration with Payfast, before going live.",
          },
        },
        {
          doc_section_content_id: 70,
          template: "standard",
          data: {
            text: "Using our Sandbox, you will be able to test your integration, once off payments and recurring payments, as well as receive ITNs, without any money changing hands.",
          },
        },
      ],
      contentRightCol: [
        {
          doc_section_content_id: 71,
          template: "request",
          data: {
            title: "Sandbox URL",
            language: "json",
            code: `https://sandbox.payfast.co.za`,
          },
        },
      ],
      contentSandboxCol: [
        {
          doc_section_content_id: 72,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Getting started:</h3> To get started proceed to the above URL and enter your email address. " +
              "The Sandbox dashboard has everything you need to test your integration. We recommend spending some time and familiarising yourself with the dashboard. There are helpful hints and tips to guide you on each page.",
          },
        },
        {
          doc_section_content_id: 73,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Payment methods:</h3> The Sandbox allows you to test your integration without any money changing hands. " +
              "The Sandbox makes use of a single payment method – a wallet with a substantially large dummy balance. While it is not possible to utilise the other payment methods in Sandbox, this will not affect your integration.",
          },
        },
        {
          doc_section_content_id: 74,
          template: "standard",
          data: {
            text: "Sandbox is simply a tool to test your integration, the actual payment screen can not be simulated while performing test transactions. The Sandbox does not make any connections to external systems (allowing all payments to be successful).",
          },
        },
        {
          doc_section_content_id: 75,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Payment notifications:</h3> The Sandbox will only send payment notifications once. " +
              "You can view all sent notifications by navigating to ITN (Instant transaction notification) in the Sandbox.",
          },
        },
        {
          doc_section_content_id: 76,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Setting a passphrase:</h3> This is required for all subscription and tokenization payments. It is the ‘salt’ added to your parameter string before generating the signature. " +
              "To add or change your passphrase, simply visit the Account Information Tab on the left hand side and enter or edit your passphrase in the ’Salt Passphrase’ input field.",
          },
        },
        {
          doc_section_content_id: 77,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Integration tools:</h3>" +
              "This section has been built to help you detect issues with your signature and assist where possible. " +
              "The signature tool tests and evaluates a given parameter string and gives a result based on your input.",
          },
        },
        {
          doc_section_content_id: 78,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Test your integration:</h3> This section was created to see what your signature should be with all your given variables. " +
              "Fill out the form and click generate signature to allow Payfast to generate the correct signature for you and then submit to Payfast Sandbox. You will have the option to send through incorrect variables to see how we respond to certain variables.",
          },
        },
        {
          doc_section_content_id: 79,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Sandbox limitations:</h3> The Sandbox does not make any connections to external systems (allowing all payments to be successful). " +
              "Because the Sandbox makes use of Payfast's own personal buyer behind the scenes, all fields containing the party table will yield Test Buyer. This will not be the case when going live.",
          },
        },
        {
          doc_section_content_id: 80,
          template: "standard",
          data: {
            text:
              "<h3 class='mb-1'>Recurring Billing tools:</h3> Recurring Billing transactions require a passphrase to be set. <br><br>" +
              "Subscriptions and tokenization payments completed in Sandbox can be viewed on your Sandbox account. Just like on the Payfast merchant account, you will be able to cancel, charge, pause and edit subscriptions. For tokenization payments, you will be able to charge and cancel them. <br><br>" +
              "When editing a subscription you can change the amount, the number of cycles (payments), the next payment date and the frequency.<br><br>" +
              "The subscription token does not display in the Sandbox, so be sure to capture this from the ITN!",
          },
        },
      ],
    };
  },
  components: {
    SectionColumn,
  },
};
</script>

<style scoped></style>
