<template>
  <v-row id="widgets" class="docScroll">
    <HeaderTemplate :data="contentHeader" />
    <SectionColumn type="full" title="MoreTyme" :content="moretyme" />
  </v-row>
</template>

<script>
import HeaderTemplate from "@/components/templates/HeaderTemplate";
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "Widgets",
  data() {
    return {
      contentHeader: {
        title: "Widgets",
        text: `<p>Add one of our Payfast widgets onto your website product page to display useful payment information.</p>`,
        svg: `<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20.5" cy="20.5" r="20.5" fill="#247373"/><path d="M13.2812 13.9375C13.1072 13.9375 12.9403 14.0066 12.8172 14.1297C12.6941 14.2528 12.625 14.4197 12.625 14.5938V19.8438C12.625 20.0178 12.6941 20.1847 12.8172 20.3078C12.9403 20.4309 13.1072 20.5 13.2812 20.5H18.5312C18.7053 20.5 18.8722 20.4309 18.9953 20.3078C19.1184 20.1847 19.1875 20.0178 19.1875 19.8438V14.5938C19.1875 14.4197 19.1184 14.2528 18.9953 14.1297C18.8722 14.0066 18.7053 13.9375 18.5312 13.9375H13.2812ZM14.5938 21.8125C14.4197 21.8125 14.2528 21.8816 14.1297 22.0047C14.0066 22.1278 13.9375 22.2947 13.9375 22.4688V26.4062C13.9375 26.5803 14.0066 26.7472 14.1297 26.8703C14.2528 26.9934 14.4197 27.0625 14.5938 27.0625H18.5312C18.7053 27.0625 18.8722 26.9934 18.9953 26.8703C19.1184 26.7472 19.1875 26.5803 19.1875 26.4062V22.4688C19.1875 22.2947 19.1184 22.1278 18.9953 22.0047C18.8722 21.8816 18.7053 21.8125 18.5312 21.8125H14.5938ZM15.25 23.125H17.875V25.75H15.25V23.125ZM21.1562 21.8125C20.9822 21.8125 20.8153 21.8816 20.6922 22.0047C20.5691 22.1278 20.5 22.2947 20.5 22.4688V27.7188C20.5 27.8928 20.5691 28.0597 20.6922 28.1828C20.8153 28.3059 20.9822 28.375 21.1562 28.375H26.4062C26.5803 28.375 26.7472 28.3059 26.8703 28.1828C26.9934 28.0597 27.0625 27.8928 27.0625 27.7188V22.4688C27.0625 22.2947 26.9934 22.1278 26.8703 22.0047C26.7472 21.8816 26.5803 21.8125 26.4062 21.8125H21.1562ZM21.1562 12.625C20.9822 12.625 20.8153 12.6941 20.6922 12.8172C20.5691 12.9403 20.5 13.1072 20.5 13.2812V19.8438C20.5 20.0178 20.5691 20.1847 20.6922 20.3078C20.8153 20.4309 20.9822 20.5 21.1562 20.5H27.7188C27.8928 20.5 28.0597 20.4309 28.1828 20.3078C28.3059 20.1847 28.375 20.0178 28.375 19.8438V13.2812C28.375 13.1072 28.3059 12.9403 28.1828 12.8172C28.0597 12.6941 27.8928 12.625 27.7188 12.625H21.1562Z" fill="white"/></svg>`,
      },
      moretyme: [
        {
          doc_section_content_id: 1,
          template: "widget",
          data: {
            type: "moretyme",
          },
        },
      ],
    };
  },
  components: {
    HeaderTemplate,
    SectionColumn,
  },
};
</script>

<style scoped></style>
