<template>
  <SystemCard :title="data.title ? data.title : ''">
    <v-simple-table class="table-bg-transparent" slot="cardData">
      <tbody class="blue-grey--text text--darken-2">
        <tr v-for="item in data.items" :key="item.name">
          <td class="font-weight-bold">{{ item.name }}</td>
          <td>{{ item.description }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </SystemCard>
</template>

<script>
import SystemCard from "@/components/layout/SystemCard";

export default {
  name: "TableTemplate",
  props: {
    data: Object,
  },
  components: {
    SystemCard,
  },
};
</script>
