<template>
  <v-app>
    <SWUpdate />
    <TopBar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "./components/layout/TopBar";
import SWUpdate from "@/components/layout/SWUpdate";

export default {
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
  }),
  components: {
    SWUpdate,
    TopBar,
  },
  computed: {
    currentRouteName() {
      return this.$route.name === null || this.$route.name === undefined
        ? "docs"
        : this.$route.name;
    },
  },
  mounted() {
    // Moving into the onscroll refers to the javascript element instance but we need the vue instance
    // To retrieve the route name onscroll
    let vueInstanceThis = this;

    // Max-height from top to update 30 is the sweet spot to avoid the blip from re rendering
    let fromTopDistance = 30;

    // Update while scrolling
    window.onscroll = function () {
      let currentHash = "#";
      let scrollableClasses = document.getElementsByClassName("docScroll");

      // Get all the elements and for each of them test where they are
      for (let i = 0; i < scrollableClasses.length; i++) {
        let element = document.querySelector(
          "#" + scrollableClasses[i].getAttribute("id")
        );
        let elementTop = element.offsetTop;
        let top = window.pageYOffset;
        let distance = top - elementTop;
        let hash = element;

        if (
          distance < fromTopDistance &&
          distance > -fromTopDistance &&
          currentHash !== hash
        ) {
          let currentHeading = hash.getAttribute("id");
          // Push the current heading to the router to implement the nav highlighting
          vueInstanceThis.$router
            .push({
              path: `/${vueInstanceThis.currentRouteName}#${currentHeading}`,
            })
            .catch(() => {});
          currentHash = hash;
        }
      }
    };
  },
};
</script>

<style lang="scss">
@import "sass/fonts";
@import "sass/global";
</style>
