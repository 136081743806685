<template>
  <div class="align-content-end pr-10">
    <v-btn
      to="/api"
      text
      class="mr-2"
      v-if="titleText === 'DOCS'"
      aria-label="Docs"
    >
      <!--<v-icon left>mdi-code-braces</v-icon>-->
      <span class="text-capitalize mainS--text white--text">API Reference</span>
    </v-btn>
    <v-btn
      to="/docs"
      text
      class="mr-2"
      v-if="titleText === 'API'"
      aria-label="API"
    >
      <!--<v-icon left>mdi-file-document</v-icon>-->
      <span class="text-capitalize mainS--text white--text">Dev Docs</span>
    </v-btn>
    <v-btn
      href="https://support.payfast.co.za/"
      target="_blank"
      text
      class="d-sm-none d-md-inline-flex mr-6"
      aria-label="Support"
      rel="noreferrer"
    >
      <!--<v-icon left>mdi-face-agent</v-icon>-->
      <span class="text-capitalize mainS--text white--text">Support</span>
    </v-btn>
    <v-btn
      href="https://registration.payfast.io"
      target="_blank"
      rounded
      color="secondary"
      class="d-sm-none d-md-inline-flex py-5 text-capitalize font-weight-bold primary--text px-8"
      aria-label="Register"
      rel="noreferrer"
    >
      Sign Up
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "TopBarLinks",
  computed: {
    titleText() {
      return this.$route.name === "api" ? "API" : "DOCS";
    },
  },
};
</script>

<style lang="scss">
.v-toolbar .v-toolbar__content {
  .v-chip {
    border-radius: 80px !important;
  }
  .v-btn__content span {
    @extend .foundersGroteskSemibold;
    text-align: center;
  }
}

</style>
