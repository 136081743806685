<template>
  <v-row id="step_4_confirm_payment" class="docScroll mt-6">
    <SectionColumn
      type="full"
      title="Step 4: Confirm payment is successful"
      :content="contentIntro"
    />
    <SectionColumn type="left" :content="contentLeftColSignature" />
    <SectionColumn type="right" :content="contentRightColSignature" />
    <SectionColumn type="left" :content="contentLeftColSignatureLast" />
    <SectionColumn type="right" :content="contentRightColSignatureLast" />
    <SectionColumn type="left" :content="contentLeftColSecurityChecks" />
    <SectionColumn type="right" :content="contentRightColSecurityChecks" />
    <SectionColumn type="left" :content="contentLeftColSecurityChecks2" />
    <SectionColumn type="right" :content="contentRightColSecurityChecks2" />
    <SectionColumn type="left" :content="contentLeftColSecurityChecks3" />
    <SectionColumn type="right" :content="contentRightColSecurityChecks3" />
    <SectionColumn type="left" :content="contentLeftColSecurityChecks4" />
    <SectionColumn type="right" :content="contentRightColSecurityChecks4" />
  </v-row>
</template>

<script>
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "StepFourConfirmation",
  data() {
    return {
      contentIntro: [
        {
          doc_section_content_id: 1,
          template: "standard",
          data: {
            text: `Before the customer is returned to the URL you specified in the “return_url” parameter, Payfast will send a notification to your “notify_url” page.`,
          },
        },
      ],
      contentLeftColSignature: [
        {
          doc_section_content_id: 2,
          template: "standard",
          data: {
            text:
              "<h3>4.1. Setting up your notification page:</h3>" +
              "<br /> " +
              "<p>" +
              "On receiving the payment notification from Payfast, return a header 200 to prevent further retries.<br>" +
              "If no 200 response is returned the notification will be re-sent immediately, then after 10 minutes and then at exponentially longer intervals until eventually stopping." +
              "</p>",
          },
        },
        {
          doc_section_content_id: 3,
          template: "alert",
          data: {
            type: "info",
            msg: `If you are testing locally you will need to have a publicly accessible URL (notify_url) in order to receive the notifications, consider using tools such as <a href="https://ngrok.com/" class="link--text" target="_blank" rel="noreferrer">NGROK</a> or <a href="https://beyondco.de/docs/expose/introduction" class="link--text" target="_blank">Expose</a> to expose your local development server to the Internet.`,
          },
        },
        {
          doc_section_content_id: 4,
          template: "standard",
          data: {
            text:
              "<br><br>" +
              "<h3>4.2. Receive the notification parameters</h3>" +
              "<br /> " +
              "<p>" +
              "As part of the notification you can expect to receive the following parameters:" +
              "</p>",
          },
        },
        {
          doc_section_content_id: 5,
          template: "attributes",
          data: {
            title: "Transaction details",
            items: [
              {
                name: "m_payment_id",
                type: "string, 100 char",
                required: false,
                description: "Unique payment ID on the merchant’s system.",
              },
              {
                name: "pf_payment_id",
                type: "integer",
                required: true,
                description: "Unique transaction ID on Payfast.",
              },
              {
                name: "payment_status",
                type: "string (CANCELLED / COMPLETE)",
                required: true,
                description:
                  "After a successful payment the status sent will be COMPLETE. When a subscription is cancelled the status will be CANCELLED.",
              },
              {
                name: "item_name",
                type: "string, 100 char",
                required: true,
                description:
                  "The name of the item being charged for, or in the case of multiple items the order number.",
              },
              {
                name: "item_description",
                type: "string, 255 char",
                required: false,
                description:
                  "The description of the item being charged for, or in the case of multiple items the order description.",
              },
              {
                name: "amount_gross",
                type: "decimal",
                required: false,
                description: "The total amount the customer paid in ZAR.",
              },
              {
                name: "amount_fee",
                type: "decimal",
                required: false,
                description:
                  "The total in fees which was deducted from the amount in ZAR.",
              },
              {
                name: "amount_net",
                type: "decimal",
                required: false,
                description:
                  "The net amount credited to the merchant’s account in ZAR.",
              },
              {
                name: "custom_int<1..5>",
                type: "integer, 255 char",
                required: false,
                description:
                  "The series of 5 custom integer variables (custom_int1, custom_int2…) originally passed by the receiver during the payment request.",
              },
              {
                name: "custom_str<1..5>",
                type: "string, 255 char",
                required: false,
                description:
                  "The series of 5 custom string variables (custom_str1, custom_str2…) originally passed by the receiver during the payment request.",
              },
            ],
          },
        },
        {
          doc_section_content_id: 6,
          template: "attributes",
          data: {
            title: "Customer details",
            items: [
              {
                name: "name_first",
                type: "string, 100 char",
                required: false,
                description: "The customer’s first name.",
              },
              {
                name: "name_last",
                type: "string, 100 char",
                required: false,
                description: "The customer’s last name.",
              },
              {
                name: "email_address",
                type: "string, 100 char",
                required: false,
                description: "The customer’s email address.",
              },
            ],
          },
        },
        {
          doc_section_content_id: 7,
          template: "attributes",
          data: {
            title: "Merchant details",
            items: [
              {
                name: "merchant_id",
                type: "integer, 8 char",
                required: true,
                description:
                  "The Merchant ID as given by the Payfast system. Used to uniquely identify the receiver’s account.",
              },
            ],
          },
        },
        {
          doc_section_content_id: 8,
          template: "attributes",
          data: {
            title: "Recurring Billing details",
            items: [
              {
                name: "token",
                type: "string, 36 char",
                required: true,
                description:
                  "Unique ID on Payfast that represents the subscription.",
              },
              {
                name: "billing_date",
                type: "date, YYYY-MM-DD",
                required: false,
                description:
                  "The date from which future subscription payments will be made. Eg. 2020-01-01. Defaults to current date if not set.",
              },
            ],
          },
        },
        {
          doc_section_content_id: 9,
          template: "attributes",
          data: {
            title: "Security information",
            items: [
              {
                name: "signature",
                type: "MD5 hash in lower case",
                required: false,
                description:
                  "A security signature of the transmitted data taking the form of an MD5 hash of all the url encoded submitted variables.",
              },
            ],
          },
        },
      ],
      contentRightColSignature: [
        {
          doc_section_content_id: 10,
          template: "request",
          data: {
            title: "Example of a Transaction Webhook Payload",
            language: "php",
            code: {
              PHP:
                "$ITN_Payload = [\n" +
                " 'm_payment_id' => 'SuperUnique1',\n" +
                " 'pf_payment_id' => '1089250',\n" +
                " 'payment_status' => 'COMPLETE',\n" +
                " 'item_name' => 'test+product',\n" +
                " 'item_description' => ,\n" +
                " 'amount_gross' => 200.00,\n" +
                " 'amount_fee' => -4.60,\n" +
                " 'amount_net' => 195.40,\n" +
                " 'custom_str1' => ,\n" +
                " 'custom_str2' => ,\n" +
                " 'custom_str3' => ,\n" +
                " 'custom_str4' => ,\n" +
                " 'custom_str5' => ,\n" +
                " 'custom_int1' => ,\n" +
                " 'custom_int2' => ,\n" +
                " 'custom_int3' => ,\n" +
                " 'custom_int4' => ,\n" +
                " 'custom_int5' => ,\n" +
                " 'name_first' => ,\n" +
                " 'name_last' => ,\n" +
                " 'email_address' => ,\n" +
                " 'merchant_id' => '10012577',\n" +
                " 'signature' => 'ad8e7685c9522c24365d7ccea8cb3db7'\n" +
                "];",
              "Node.js":
                "const ITN_Payload = {\n" +
                " 'm_payment_id': 'SuperUnique1',\n" +
                " 'pf_payment_id': '1089250',\n" +
                " 'payment_status': 'COMPLETE',\n" +
                " 'item_name': 'test+product',\n" +
                " 'item_description': 'test+description' ,\n" +
                " 'amount_gross': 200.00,\n" +
                " 'amount_fee': -4.60,\n" +
                " 'amount_net': 195.40,\n" +
                " 'custom_str1': '',\n" +
                " 'custom_str2': '',\n" +
                " 'custom_str3': '',\n" +
                " 'custom_str4': '',\n" +
                " 'custom_str5': '',\n" +
                " 'custom_int1': '',\n" +
                " 'custom_int2': '',\n" +
                " 'custom_int3': '',\n" +
                " 'custom_int4': '',\n" +
                " 'custom_int5': '',\n" +
                " 'name_first': '',\n" +
                " 'name_last': '',\n" +
                " 'email_address': '',\n" +
                " 'merchant_id': '10000100',\n" +
                " 'signature': 'ad8e7685c9522c24365d7ccea8cb3db7'\n" +
                "};",
              Python: `ITN_Payload = {
 'm_payment_id': 'SuperUnique1',
 'pf_payment_id': '1089250',
 'payment_status': 'COMPLETE',
 'item_name': 'test+product',
 'item_description': 'test+description' ,
 'amount_gross': 200.00,
 'amount_fee': -4.60,
 'amount_net': 195.40,
 'custom_str1': '',
 'custom_str2': '',
 'custom_str3': '',
 'custom_str4': '',
 'custom_str5': '',
 'custom_int1': '',
 'custom_int2': '',
 'custom_int3': '',
 'custom_int4': '',
 'custom_int5': '',
 'name_first': '',
 'name_last': '',
 'email_address': '',
 'merchant_id': '10000100',
 'signature': 'ad8e7685c9522c24365d7ccea8cb3db7'
}`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
      contentLeftColSignatureLast: [
        {
          doc_section_content_id: 11,
          template: "standard",
          data: {
            text:
              "<h3>4.3. Conduct security checks</h3>" +
              "<br /> " +
              "<p>Conduct four security checks to ensure that the data you are receiving is correct, from the correct source and hasn’t been altered; you should not continue the process if a test fails!</p>",
          },
        },
      ],
      contentRightColSignatureLast: [
        {
          doc_section_content_id: 12,
          template: "request",
          data: {
            title: "Notification page",
            language: "php",
            code: {
              PHP:
                "<?php\n" +
                "// Tell Payfast that this page is reachable by triggering a header 200\n" +
                "header( 'HTTP/1.0 200 OK' );\n" +
                "flush();\n\n" +
                "define( 'SANDBOX_MODE', true );\n" +
                "$pfHost = SANDBOX_MODE ? 'sandbox.payfast.co.za' : 'www.payfast.co.za';\n" +
                "// Posted variables from ITN\n" +
                "$pfData = $_POST;\n" +
                "\n" +
                "// Strip any slashes in data\n" +
                "foreach( $pfData as $key => $val ) {\n" +
                "    $pfData[$key] = stripslashes( $val );\n" +
                "}\n\n" +
                "// Convert posted variables to a string\n" +
                "foreach( $pfData as $key => $val ) {\n" +
                "    if( $key !== 'signature' ) {\n" +
                "        $pfParamString .= $key .'='. urlencode( $val ) .'&';\n" +
                "    } else {\n" +
                "        break;\n" +
                "    }\n" +
                "}\n" +
                "\n" +
                "$pfParamString = substr( $pfParamString, 0, -1 );",
              "Node.js":
                'const axios = require("axios");\n' +
                'const crypto = require("crypto");\n' +
                "const dns = require('dns');\n\n" +
                "const testingMode = true;\n" +
                'const pfHost = testingMode ? "sandbox.payfast.co.za" : "www.payfast.co.za";\n\n' +
                "const pfData = JSON.parse(JSON.stringify(req.body));\n\n" +
                'let pfParamString = "";\n' +
                "for (let key in pfData) {\n" +
                '  if(pfData.hasOwnProperty(key) && key !== "signature"){\n' +
                '    pfParamString +=`${key}=${encodeURIComponent(pfData[key].trim()).replace(/%20/g, "+")}&`;\n' +
                "  }\n" +
                "}\n\n" +
                "// Remove last ampersand\n" +
                "pfParamString = pfParamString.slice(0, -1);\n",
              Python: `import requests
import hashlib
import urllib.parse
import socket
from werkzeug.urls import url_parse
from flask import request

SANDBOX_MODE = True

pfHost = 'sandbox.payfast.co.za' if SANDBOX_MODE else 'www.payfast.co.za'

# Get posted variables from ITN and convert to a string
pfData = {}
postData = request.get_data().decode().split('&')
for i in range(0,len(postData)):
    splitData = postData[i].split('=')
    pfData[splitData[0]] = splitData[1]

pfParamString = ""
for key in pfData:
# Get all the data from Payfast and prepare parameter string
    if key != 'signature':
        pfParamString += key + "=" + urllib.parse.quote_plus(pfData[key].replace("+", " ")) + "&"
# After looping through, cut the last & or append your passphrase
# payload += "passphrase=SecretPassphrase123"
pfParamString = pfParamString[:-1]`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
      contentLeftColSecurityChecks: [
        {
          doc_section_content_id: 34,
          template: "standard",
          data: {
            text:
              "<p>" +
              "<ul>" +
              "<li>" +
              "<h3>Verify the signature</h3>" +
              "<br><p>Verify the security signature in the notification; this is done in a similar way that the signature that you generated for stage one of the user payment flow. </p>" +
              "<p>The string that gets created needs to include all fields posted from Payfast.</p>" +
              "</li>" +
              "</ul>" +
              "<p>",
          },
        },
      ],
      contentRightColSecurityChecks: [
        {
          doc_section_content_id: 13,
          template: "request",
          data: {
            title: "Verify Signature",
            language: "php",
            code: {
              PHP:
                "<?php\n" +
                "function pfValidSignature( $pfData, $pfParamString, $pfPassphrase = null ) {\n" +
                "    // Calculate security signature\n" +
                "    if($pfPassphrase === null) {\n" +
                "        $tempParamString = $pfParamString;\n" +
                "    } else {\n" +
                "        $tempParamString = $pfParamString.'&passphrase='.urlencode( $pfPassphrase );\n" +
                "    }\n" +
                "\n" +
                "    $signature = md5( $tempParamString );\n" +
                "    return ( $pfData['signature'] === $signature );\n" +
                "}",
              "Node.js":
                "const pfValidSignature = (pfData, pfParamString, pfPassphrase = null ) => {\n" +
                "  // Calculate security signature\n" +
                "  let tempParamString = '';\n" +
                "  if (pfPassphrase !== null) {\n" +
                '    pfParamString +=`&passphrase=${encodeURIComponent(pfPassphrase.trim()).replace(/%20/g, "+")}`;\n' +
                "  }\n" +
                "\n" +
                '  const signature = crypto.createHash("md5").update(pfParamString).digest("hex");\n' +
                "  return pfData['signature'] === signature;\n" +
                "};",
              Python: `def pfValidSignature(pfData, pfParamString):
  # Generate our signature from Payfast parameters
  signature = hashlib.md5(pfParamString.encode()).hexdigest()
  return (pfData.get('signature') == signature)`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
      contentLeftColSecurityChecks2: [
        {
          doc_section_content_id: 14,
          template: "standard",
          data: {
            text:
              "<p>" +
              "<ul>" +
              "<li>" +
              "<h3>Check that the notification has come from a valid Payfast domain</h3>" +
              "<br><p>The following is a list of valid domains: <br> - www.payfast.co.za<br> - w1w.payfast.co.za<br> - w2w.payfast.co.za<br> - sandbox.payfast.co.za  </p>" +
              "</li>" +
              "</ul>" +
              "<p>",
          },
        },
      ],
      contentRightColSecurityChecks2: [
        {
          doc_section_content_id: 15,
          template: "request",
          data: {
            title: "Check valid Payfast domain",
            language: "php",
            code: {
              PHP:
                "<?php\n" +
                "function pfValidIP() {\n" +
                "    // Variable initialization\n" +
                "    $validHosts = array(\n" +
                "        'www.payfast.co.za',\n" +
                "        'sandbox.payfast.co.za',\n" +
                "        'w1w.payfast.co.za',\n" +
                "        'w2w.payfast.co.za',\n" +
                "        );\n" +
                "\n" +
                "    $validIps = [];\n" +
                "\n" +
                "    foreach( $validHosts as $pfHostname ) {\n" +
                "        $ips = gethostbynamel( $pfHostname );\n" +
                "\n" +
                "        if( $ips !== false )\n" +
                "            $validIps = array_merge( $validIps, $ips );\n" +
                "    }\n" +
                "\n" +
                "    // Remove duplicates\n" +
                "    $validIps = array_unique( $validIps );\n" +
                "    $referrerIp = gethostbyname(parse_url($_SERVER['HTTP_REFERER'])['host']);" +
                "\n" +
                "    if( in_array( $referrerIp, $validIps, true ) ) {\n" +
                "        return true;\n" +
                "    }\n" +
                "    return false;\n" +
                "}",
              "Node.js": `async function ipLookup(domain){
  return new Promise((resolve, reject) => {
    dns.lookup(domain, {all: true}, (err, address, family) => {
      if(err) {
        reject(err)
      } else {
        const addressIps = address.map(function (item) {
         return item.address;
        });
        resolve(addressIps);
      }
    });
  });
}

const pfValidIP = async (req) => {
  const validHosts = [
    'www.payfast.co.za',
    'sandbox.payfast.co.za',
    'w1w.payfast.co.za',
    'w2w.payfast.co.za'
  ];

  let validIps = [];
  const pfIp = req.headers['x-forwarded-for'] || req.connection.remoteAddress;

  try{
    for(let key in validHosts) {
      const ips = await ipLookup(validHosts[key]);
      validIps = [...validIps, ...ips];
    }
  } catch(err) {
    console.error(err);
  }

  const uniqueIps = [...new Set(validIps)];

  if (uniqueIps.includes(pfIp)) {
    return true;
  }
  return false;
};`,
              Python: `def pfValidIP():
    valid_hosts = [
    'www.payfast.co.za',
    'sandbox.payfast.co.za',
    'w1w.payfast.co.za',
    'w2w.payfast.co.za',
    ]
    valid_ips = []

    for item in valid_hosts:
        ips = socket.gethostbyname_ex(item)
        if ips:
            for ip in ips:
                if ip:
                    valid_ips.append(ip)
    # Remove duplicates from array
    clean_valid_ips = []
    for item in valid_ips:
        # Iterate through each variable to create one list
        if isinstance(item, list):
            for prop in item:
                if prop not in clean_valid_ips:
                    clean_valid_ips.append(prop)
        else:
            if item not in clean_valid_ips:
                clean_valid_ips.append(item)

    # Security Step 3, check if referrer is valid
    if url_parse(request.headers.get("Referer")).host not in clean_valid_ips:
        return False
    else:
        return True`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
      contentLeftColSecurityChecks3: [
        {
          doc_section_content_id: 16,
          template: "standard",
          data: {
            text:
              "<p>" +
              "<ul>" +
              "<li>" +
              "<h3>Compare payment data</h3>" +
              "<br><p>The amount you expected the customer to pay should match the “amount_gross” value sent in the notification.  </p>" +
              "</li>" +
              "</ul>" +
              "<p>",
          },
        },
      ],
      contentRightColSecurityChecks3: [
        {
          doc_section_content_id: 17,
          template: "request",
          data: {
            title: "Compare payment data",
            language: "php",
            code: {
              PHP:
                "<?php\n" +
                "function pfValidPaymentData( $cartTotal, $pfData ) {\n" +
                "    return !(abs((float)$cartTotal - (float)$pfData['amount_gross']) > 0.01);\n" +
                "}",
              "Node.js": `const pfValidPaymentData = ( cartTotal, pfData ) => {
    return Math.abs(parseFloat(cartTotal) - parseFloat(pfData['amount_gross'])) <= 0.01;
};`,
              Python: `def pfValidPaymentData(cartTotal, pfData):
    return not (abs(float(cartTotal)) - float(pfData.get('amount_gross'))) > 0.01`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
      contentLeftColSecurityChecks4: [
        {
          doc_section_content_id: 18,
          template: "standard",
          data: {
            text: `<p>
                <ul>
                  <li>
                    <h3>Perform a server request to confirm the details</h3>
                    <br><p>Validate the data that you have received from Payfast by contacting our server and confirming the order details.</p>
                    <p><b>Live: </b>https://www.payfast.co.za/&#8203;eng/query/validate <br><b>Sandbox: </b>https://sandbox.payfast.co.za/&#8203;eng/query/validate </p>
                  </li>
                </ul>
            <p>&nbsp;`,
          },
        },
        {
          doc_section_content_id: 19,
          template: "table",
          data: {
            title: "Curl Options",
            items: [
              {
                name: "CURLOPT_RETURNTRANSFER",
                description:
                  "Set to TRUE to return the transfer as a string of the return value of curl_exec() instead of outputting it out directly.",
              },
              {
                name: "CURLOPT_HEADER",
                description:
                  "Set to FALSE to exclude the header in the output.",
              },
              {
                name: "CURLOPT_SSL_VERIFYHOST",
                description:
                  "Set to 2 to check the existence of a common name in the SSL peer certificate, and to also verify that it matches the hostname provided.",
              },
              {
                name: "CURLOPT_SSL_VERIFYPEER",
                description:
                  "This option determines whether curl verifies the authenticity of the peer’s certificate. The default value is 1.",
              },
              {
                name: "CURLOPT_URL",
                description:
                  "Set to the Payfast query validation URL, eg. https://www.payfast.co.za/eng/query/validate. This can also be set when initializing a session with curl_init().",
              },
              {
                name: "CURLOPT_POST",
                description:
                  "Set to TRUE to do a regular HTTP POST. This POST is the normal application/x-www-form-urlencoded kind, most commonly used by HTML forms.",
              },
              {
                name: "CURLOPT_POSTFIELDS",
                description:
                  "The full data to post via HTTP to Payfast. This parameter is passed as a urlencoded string, such as:\n" +
                  "m_payment_id=01&pf_payment_id=01234..",
              },
            ],
          },
        },
      ],
      contentRightColSecurityChecks4: [
        {
          doc_section_content_id: 20,
          template: "request",
          data: {
            title: "Server request to confirm details",
            language: "php",
            code: {
              PHP:
                "<?php\n" +
                "function pfValidServerConfirmation( $pfParamString, $pfHost = 'sandbox.payfast.co.za', $pfProxy = null ) {\n" +
                "    // Use cURL (if available)\n" +
                "    if( in_array( 'curl', get_loaded_extensions(), true ) ) {\n" +
                "        // Variable initialization\n" +
                "        $url = 'https://'. $pfHost .'/eng/query/validate';\n" +
                "\n" +
                "        // Create default cURL object\n" +
                "        $ch = curl_init();\n" +
                "    \n" +
                "        // Set cURL options - Use curl_setopt for greater PHP compatibility\n" +
                "        // Base settings\n" +
                "        curl_setopt( $ch, CURLOPT_USERAGENT, NULL );  // Set user agent\n" +
                "        curl_setopt( $ch, CURLOPT_RETURNTRANSFER, true );      // Return output as string rather than outputting it\n" +
                "        curl_setopt( $ch, CURLOPT_HEADER, false );             // Don't include header in output\n" +
                "        curl_setopt( $ch, CURLOPT_SSL_VERIFYHOST, 2 );\n" +
                "        curl_setopt( $ch, CURLOPT_SSL_VERIFYPEER, true );\n" +
                "        \n" +
                "        // Standard settings\n" +
                "        curl_setopt( $ch, CURLOPT_URL, $url );\n" +
                "        curl_setopt( $ch, CURLOPT_POST, true );\n" +
                "        curl_setopt( $ch, CURLOPT_POSTFIELDS, $pfParamString );\n" +
                "        if( !empty( $pfProxy ) )\n" +
                "            curl_setopt( $ch, CURLOPT_PROXY, $pfProxy );\n" +
                "    \n" +
                "        // Execute cURL\n" +
                "        $response = curl_exec( $ch );\n" +
                "        curl_close( $ch );\n" +
                "        if ($response === 'VALID') {\n" +
                "            return true;\n" +
                "        }\n" +
                "    }\n" +
                "    return false;\n" +
                "}",
              "Node.js":
                "const pfValidServerConfirmation = async (pfHost, pfParamString) => {\n" +
                "    const result = await axios.post(`https://${pfHost}/eng/query/validate`, pfParamString)\n" +
                "        .then((res) => {\n" +
                "            return res.data;\n" +
                "        })\n" +
                "        .catch((error) => {\n" +
                "            console.error(error)\n" +
                "        });\n" +
                "   return result === 'VALID';\n" +
                "};",
              Python: `def pfValidServerConfirmation(pfParamString, pfHost = 'sandbox.payfast.co.za'):
    url = f"https://{pfHost}/eng/query/validate"
    headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
        }
    response = requests.post(url, data=pfParamString, headers=headers)
    return response.text == 'VALID'`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
        {
          doc_section_content_id: 21,
          template: "request",
          data: {
            title: "Bringing the checks together",
            language: "php",
            code: {
              PHP: `<?php
$check1 = pfValidSignature($pfData, $pfParamString);
$check2 = pfValidIP();
$check3 = pfValidPaymentData($cartTotal, $pfData);
$check4 = pfValidServerConfirmation($pfParamString, $pfHost);

if($check1 && $check2 && $check3 && $check4) {
    // All checks have passed, the payment is successful
} else {
    // Some checks have failed, check payment manually and log for investigation
} `,
              "Node.js": `const check1 = pfValidSignature(pfData, pfParamString, passPhrase);
const check2 = pfValidIP(req);
const check3 = pfValidPaymentData( cartTotal, pfData );
const check4 = pfValidServerConfirmation(pfHost, pfParamString);

if(check1 && check2 && check3 && check4) {
    // All checks have passed, the payment is successful
} else {
    // Some checks have failed, check payment manually and log for investigation
}`,
              Python: `check1 = pfValidSignature(pfData, pfParamString)
check2 = pfValidIP()
check3 = pfValidPaymentData(cartTotal, pfData)
check4 = pfValidServerConfirmation(pfParamString, pfHost);

if(check1 and check2 and check3 and check4):
    # All checks have passed, the payment is successful
else:
    # Some checks have failed, check payment manually and log for investigation`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
    };
  },
  components: {
    SectionColumn,
  },
};
</script>
