export const sidebarDocs = [
  {
    title: "Home",
    attr_id: "home",
    tags: ["api", "shopping cart", "pay now", "integrate"],
  },
  {
    title: "Custom Integration",
    attr_id: "custom-integration-group",
    items: [
      {
        title: "Getting Started",
        attr_id: "quickstart",
        tags: ["Custom", "checkout"],
      },
      {
        title: "Step 1: Form Fields",
        attr_id: "step_1_form_fields",
        tags: ["payment", "form fields", "notify_url"],
      },
      {
        title: "Step 2: Security signature",
        attr_id: "step_2_signature",
        tags: ["signature", "security", "passphrase"],
      },
      {
        title: "Step 3: Pay with PayFast",
        attr_id: "step_3_pay_on_payfast",
        tags: ["payment", "custom"],
      },
      {
        title: "Step 4: Confirm payment",
        attr_id: "step_4_confirm_payment",
        tags: [
          "ITN",
          "webhook",
          "notify_url",
          "notify",
          "confirm payment",
          "validate",
        ],
      },
    ],
  },
  {
    title: "Onsite Payments",
    attr_id: "onsite_payments",
    tags: ["Onsite Payments", "online store"],
  },
  {
    title: "Recurring Billing",
    attr_id: "recurring_billing-group",
    items: [
      {
        title: "Overview",
        attr_id: "recurring_billing",
        tags: ["Recurring Billing", "subscriptions"],
      },
      {
        title: "Subscriptions",
        attr_id: "subscriptions",
        tags: ["recurring", "subscriptions"],
      },
      {
        title: "Tokenization",
        attr_id: "tokenization",
        tags: ["recurring", "tokenization"],
      },
      {
        title: "Update Card",
        attr_id: "recurring_card_update",
        tags: ["recurring", "card update"],
      },
      {
        title: "Recurring Functionality",
        attr_id: "recurring_functionality",
        tags: ["recurring", "maintenance"],
      },
    ],
  },
  {
    title: "Split Payments",
    attr_id: "splitpayments",
    tags: ["Split Payments", "portion", "third party"],
  },
  {
    title: "PCI Compliance",
    attr_id: "pci-compliance",
    tags: ["pci", "compliance", "PASA", "DSS"],
  },
  {
    title: "Testing and Tools",
    attr_id: "testing-tools-group",
    items: [
      {
        title: "Overview",
        attr_id: "testing-tools",
        tags: ["testing", "tools"],
      },
      {
        title: "SDKs",
        attr_id: "sdk",
        tags: ["testing", "tools", "sdk", "library"],
      },
      {
        title: "Sandbox",
        attr_id: "sandbox",
        tags: ["testing", "sandbox"],
      },
      {
        title: "Test transaction setup",
        attr_id: "test_transaction_setup",
        tags: ["testing", "integration"],
      },
      {
        title: "Going Live",
        attr_id: "go_live",
        tags: ["live", "production", "test"],
      },
    ],
  },
  {
    title: "Widgets",
    attr_id: "widgets-group",
    items: [
      {
        title: "MoreTyme",
        attr_id: "widgets-moretyme",
        tags: ["widget", "moretyme", "tyme"],
      },
    ],
  },
  {
    title: "Ports and IP Addresses",
    attr_id: "ports-ips",
    tags: ["ports", "IP address", "system admin", "white-listing"],
  },
  {
    title: "FAQs",
    attr_id: "faqs",
    tags: ["faq", "knowledge", "questions", "problems"],
  },
];
