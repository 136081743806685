<template>
  <div>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      height="80"
      elevation="8"
    >
      <v-row no-gutters class="ml-n4">
        <v-col cols="12" class="col-sm-5 col-md-4 col-lg-6 col-xl-7 pl-0">
          <div :class="titleStyle">
            <div class="float-left mt-4 mx-1">
              <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
              />
            </div>
            <div class="float-left mt-5">
              <a
                href="https://payfast.io/"
                target="_blank"
                rel="noreferrer"
              >
                <v-img
                  src="../../assets/ni_payfast_logo.png"
                  alt="Payfast"
                  width="116px"
                  height="47px"
                />
              </a>
            </div>
            <div class="float-left mt-7 ml-3">
              <v-chip
                class="px-2 py-0"
                color="primaryAccent"
                label
                small
                text-color="primary"
              >
                <b>{{ titleText }}</b>
              </v-chip>
            </div>
            <SideBar v-model="drawer" />
          </div>
        </v-col>
        <v-col
          align-self="center"
          class="d-none d-sm-inline-block col-sm-4 col-md-3 col-lg-2 col-xl-2 pl-4"
        >
          <Searchbar />
        </v-col>
        <v-col
          align-self="center"
          class="d-none d-sm-flex justify-end col-sm-3 col-md-5 col-lg-4 col-xl-3"
        >
          <TopBarLinks />
        </v-col>
      </v-row>
    </v-app-bar>
    <SideBar :drawer="drawer" v-on:update:drawer="drawer = $event" />
  </div>
</template>

<script>
import SideBar from "./SideBar";
import Searchbar from "./Searchbar";
import TopBarLinks from "./TopBarLinks";

export default {
  name: "TopBar",
  components: { TopBarLinks, Searchbar, SideBar },
  data: () => ({
    drawer: null,
    windowWidth: window.innerWidth,
  }),
  computed: {
    titleText() {
      return this.$route.name === "api" ? "API" : "Docs";
    },
    titleStyle() {
      return this.windowWidth > 1264
        ? "header_bg_title header_bg_color"
        : "header_bg_title";
    },
  },
};
</script>

<style lang="scss" scoped>
$btn-active-opacity: 1 !default;
.header_bg_title {
  height: 80px;
  width: 256px;
  margin: 0;
}
.header_bg_color {
  background-color: $forestGreen;
}
</style>
