import { render, staticRenderFns } from "./AttributesChildTemplate.vue?vue&type=template&id=4ea52891&scoped=true&"
import script from "./AttributesChildTemplate.vue?vue&type=script&lang=js&"
export * from "./AttributesChildTemplate.vue?vue&type=script&lang=js&"
import style0 from "./AttributesChildTemplate.vue?vue&type=style&index=0&id=4ea52891&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea52891",
  null
  
)

export default component.exports