import { render, staticRenderFns } from "./SectionColumn.vue?vue&type=template&id=9b0f0344&scoped=true&"
import script from "./SectionColumn.vue?vue&type=script&lang=js&"
export * from "./SectionColumn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b0f0344",
  null
  
)

export default component.exports