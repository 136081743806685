<template>
  <v-row class="section docScroll" id="step_2_signature">
    <SectionColumn
      type="left"
      title="Step 2: Create security signature"
      :content="contentIntroLeft"
    />
    <SectionColumn type="right" :content="contentIntroRight" />
    <SectionColumn type="left" :content="contentLeftColSignature" />
    <SectionColumn type="right" :content="contentRightColSignature" />
  </v-row>
</template>

<script>
import SectionColumn from "@/components/layout/SectionColumn";

export default {
  name: "StepTwoSignature",
  data() {
    return {
      contentIntroLeft: [
        {
          doc_section_content_id: 1,
          template: "standard",
          data: {
            text: `A generated MD5 signature must be passed as an additional hidden input and this hash is then used to ensure the integrity of the data transfer.`,
          },
        },
      ],
      contentIntroRight: [
        {
          doc_section_content_id: 2,
          template: "request",
          data: {
            title: "Security Signature",
            language: "html",
            code: '<input type="hidden" name="signature" value="f103e22c0418655fb03991538c51bfd5">',
          },
        },
      ],
      contentLeftColSignature: [
        {
          doc_section_content_id: 3,
          template: "standard",
          data: {
            text:
              "<h3>To generate the signature:</h3>" +
              "<br /> " +
              "<p><b>1. </b>" +
              "<strong>Concatenation of the name value pairs of all the non-blank variables with ‘&’ used as a separator </strong>" +
              "</p>",
          },
        },
        {
          doc_section_content_id: 4,
          template: "alert",
          data: {
            type: "warning",
            msg: `<b>Variable order:</b> The pairs must be listed in the <b>order in which they appear</b> in the attributes description. eg. name_first=John&name_last=Doe&#8203;&email_address=…<br><em>* Do not use the <b>API signature</b> format, which uses alphabetical ordering!</em>`,
          },
        },
        {
          doc_section_content_id: 5,
          template: "standard",
          data: {
            text:
              "<p><b>2. </b>" +
              "<strong>Add your passphrase</strong>" +
              "<br>" +
              "The passphrase is an extra security feature, used as a ‘salt’, and is set by the Merchant in the Settings section of their Payfast Dashboard." +
              "<br>" +
              "Add the passphrase to the end of the below string." +
              "<br>" +
              "<em>E.g. name_first=John&name_last=Doe&#8203;&email_address=…&passphrase=...</em><br><br>" +
              "The resultant URL encoding must be in upper case (eg. http%3A%2F%2F), and spaces encoded as ‘+’." +
              "<p><b>3. </b>" +
              "<strong>MD5 the parameter string and pass it as a hidden input named “signature”</strong>" +
              "</p>",
          },
        },
        {
          doc_section_content_id: 6,
          template: "alert",
          data: {
            type: "warning",
            msg: `<b>Troubleshooting:</b> For troubleshooting signature mismatch issues check out our knowledge base <a href='https://support.payfast.co.za/portal/en/kb/articles/common-causes-of-a-failed-integration-signature-mismatch' target='_blank' title='Common causes for failed signature' class='link--text' rel="noreferrer">Common causes of a failed integration / signature mismatch</a>`,
          },
        },
      ],
      contentRightColSignature: [
        {
          doc_section_content_id: 7,
          template: "request",
          data: {
            title: "Signature generation",
            language: "php",
            code: {
              PHP: `<?php
/**
 * @param array $data
 * @param null $passPhrase
 * @return string
 */
function generateSignature($data, $passPhrase = null) {
    // Create parameter string
    $pfOutput = '';
    foreach( $data as $key => $val ) {
        if($val !== '') {
            $pfOutput .= $key .'='. urlencode( trim( $val ) ) .'&';
        }
    }
    // Remove last ampersand
    $getString = substr( $pfOutput, 0, -1 );
    if( $passPhrase !== null ) {
        $getString .= '&passphrase='. urlencode( trim( $passPhrase ) );
    }
    return md5( $getString );
}`,
              "Node.js":
                'const crypto = require("crypto");\n\n' +
                "const generateSignature = (data, passPhrase = null) => {\n" +
                "  // Create parameter string\n" +
                '  let pfOutput = "";\n' +
                "  for (let key in data) {\n" +
                "    if(data.hasOwnProperty(key)){\n" +
                '      if (data[key] !== "") {\n' +
                '        pfOutput +=`${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, "+")}&`\n' +
                "      }\n" +
                "    }\n" +
                "  }\n\n" +
                "  // Remove last ampersand\n" +
                "  let getString = pfOutput.slice(0, -1);\n" +
                "  if (passPhrase !== null) {\n" +
                '    getString +=`&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`;\n' +
                "  }\n\n" +
                '  return crypto.createHash("md5").update(getString).digest("hex");\n' +
                "};",
              Python: `import hashlib
import urllib.parse

pfData = {
    "merchant_id": "10000100",
    "merchant_key": "46f0cd694581a",
    "return_url": "https://www.example.com",
    "notify_url": "https://www.example.com/notify_url",
    "m_payment_id": "UniqueId",
    "amount": "200",
    "item_name": "test product"
}

def generateSignature(dataArray, passPhrase = ''):
    payload = ""
    for key in dataArray:
        # Get all the data from Payfast and prepare parameter string
        payload += key + "=" + urllib.parse.quote_plus(dataArray[key].replace("+", " ")) + "&"
    # After looping through, cut the last & or append your passphrase
    payload = payload[:-1]
    if passPhrase != '':
        payload += f"&passphrase={passPhrase}"
    return hashlib.md5(payload.encode()).hexdigest()

passPhrase = 'jt7NOE43FZPn'
signature = generateSignature(pfData, passPhrase)`,
            },
            codeExamples: ["PHP", "Node.js", "Python"],
          },
        },
      ],
    };
  },
  components: {
    SectionColumn,
  },
};
</script>

<style scoped></style>
