import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/sass/variables.scss"],
  theme: {
    themes: {
      light: {
        primary: "#022D2D",
        primaryAccent: "#E8F6D1",
        secondary: "#CAD551",
        error: "#D65C5C",
        info: "#022D2D",
        success: "#62C076",
        warning: "#F19F41",
      },
    },
  },
});

