import Vue from "vue";
import VueRouter from "vue-router";
import Docs from "../views/docs/Docs.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/docs",
  },
  {
    path: "/docs",
    name: "docs",
    component: Docs,
  },
  {
    path: "/api",
    name: "api",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/api/Api.vue"),
  },
  { path: "*", redirect: "/docs" },
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 80 },
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
